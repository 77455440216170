import { ChangeDetectorRef, Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'app/core/settings/appconstants';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { AppSettingsService } from '../../services/app-settings.service';
import { AppLoadService } from 'app/core/services/appload.service';
import { ProductService } from '../../../products/services/product.service';
import { Untemplate } from '../../../products/model/untemplate';
import { MatStepper } from '@angular/material/stepper';
import { EmailEditorComponent } from 'angular-email-editor';
import { interval, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExternalWebsiteService } from './externalwebsite.service';
import { ExternalWebsiteAddTemplateDlgComponent } from '../../../../public/internal-website-add-template-dlg/external-website-add-template-dlg.component';
import { ExternalWebsiteSeosettingTemplateDlgComponent } from '../external-website-seosetting-template-dlg/external-website-seosetting-template-dlg.component';
import { FuseConfirmDialogComponent } from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { EventsComponent } from 'app/main/public/events/eventlist/events.component';
import { CoursesComponent } from '../../../../public/courses/courselist/courses.component';
import { ScheduleListViewComponent } from '../../../../public/calendar/schedule-list/schedule-listview.component';
import { Router } from '@angular/router';
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { ServiceManangeBatchComponent } from '../../../services/service-manange-batch/service-manange-batch.component';
import { InternalWebsitePublishUnpublishDlgComponent } from '../../../../public/internal-website-publish-unpublish-dlg/internal-website-publish-unpublish-dlg.component';
import { ProductListComponent } from '../../../products/product-list/product-list.component';
import { CustomFormsListComponent } from '../../../custom-forms/custom-forms-list/custom-forms-list.component';
import { DonationsListComponent } from '../../../../admin/donations/donations-list/donations-list.component';
import {
  EventlistforgalleryComponent
} from "../../../../public/events/eventlistforgallery/eventlistforgallery.component";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-external-website',
  templateUrl: './external-website.component.html',
  styleUrls: ['./external-website.component.scss'],
})
export class ExternalWebsiteComponent implements OnInit, OnDestroy {
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isInternalSiteLoading: boolean;
  isInternalSubmitLoading: boolean = false;
  ExternalPages: FormArray = this._formBuilder.array([]);
  domainRegex = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  externalLinkValue: string = "";
  // standardPageForm: FormGroup = new FormGroup({
  //     header: new FormControl(this.header, Validators.required),
  //     subHeader: new FormControl(this.subHeader, Validators.required),
  //     footer: new FormControl(this.footer, Validators.required)
  // });
  subs = new SubSink();
  back = `${AppConstants.SL_APP_SETTINGS_ROUTE}/website`;
  tenant: any;
  selectedPageUnTemplates: Untemplate[] = [];
  selectedPageUntotal: number = 0;
  isTemplateSkip: boolean = true;
  isInternalPageEdit: boolean = false;
  isPageFooterEdit: boolean = false;
  isPageSubHeaderEdit: boolean = false;
  isPageHeaderEdit: boolean = false;
  isFromNoWebsite: boolean = false;
  isPageNameEdit: boolean = false;
  emptyForm: boolean = true;
  isExternalWebsiteUrl: boolean = false;
  design: any;
  editorHtml: any;
  dialogRef: any;
  internalWebsiteSeoDialogRef: any;
  pingTenant: any;
  header: string = 'kkk';
  subHeader: string;
  footer: string;
  @ViewChild('editor')
  private emailEditor: EmailEditorComponent;
  isEditorLoaded$: Subject<any> = new Subject<any>();
  source = interval(1000);
  url: string = '/home';
  pageTitle: string = 'Home';
  public isPublished: boolean;
  isShowNavigation: boolean = true;
  isShowFooter: boolean = true;
  selectedPageUhhtml: any;
  selectedPageUnJson: any;
  isDefaultWebsitePagesSelected = true;
  isAddWebSiteObject: boolean = true;
  private mandatoryPages: any[] = ['home', 'about', 'contact', 'calendar', 'donations', 'forms', 'events', 'courses', 'mediagallery','privacypolicy','termsofservice'];
  pageId: string;
  htmlEditorVal: string = '<html>\n<head>\n <style type="text/css">\n	</style>\n	<script type="javascript">\n </script>\n</head>\n<body><p><b>Date Here</b></p></p></body>\n</html>';
  availablePageList = [];
  options = {
    displayMode: 'web',
    appearance: {
      theme: 'light',
    },
  };
  isRawTemplateSelected = true;
  @ViewChild('stepper') private myStepper: MatStepper;
  pageName = 'home';
  externalWebsiteForm: FormGroup;
  eventsComponentInjector: Promise<Type<EventsComponent>>;
  coursesComponentInjector: Promise<Type<CoursesComponent>>;
  donationComponentInjector: Promise<Type<DonationsListComponent>>;
  merchandiseComponentInjector: Promise<Type<ProductListComponent>>;
  calenderComponentInjector: Promise<Type<ScheduleListViewComponent>>;
  formsComponentInjector: Promise<Type<CustomFormsListComponent>>;
  eventGalleryComponentInjector: Promise<Type<EventlistforgalleryComponent>>;
  @ViewChild(MonacoEditorComponent, { static: false })
  monacoComponent: MonacoEditorComponent;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'vs-dark',
    language: 'html',
    roundedSelection: true,
    autoIndent: 'full',
  };
  modelUri: monaco.Uri;
  editorInstance: monaco.editor.IStandaloneCodeEditor;

  constructor(
    public toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private AppSettingsService: AppSettingsService,
    private _appLoadService: AppLoadService,
    private _productsService: ProductService,
    private _changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public _matDialog: MatDialog,
    private _externalWebsiteService: ExternalWebsiteService,
    private router: Router,
    private monacoLoaderService: MonacoEditorLoaderService,
  ) {
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1),
      )
      .subscribe(() => {
        this.registerMonacoJsonSchemaValidator();
        this.registerMonacoCustomTheme();

        this.editorInstance = monaco.editor.create(document.getElementById('container'), {
          value: this.htmlEditorVal, 
          language: 'html',
          theme: 'vs-dark',
        });

        if (this.editorInstance) {
          this.editorInstance.setValue(this.htmlEditorVal);
        }
      });
  }

  ngOnInit() {
    this.isInternalPageEdit = false;
    this.pingTenant = this._appLoadService.getTenantPing();
    // this.getPluginTenant();
    this.getUntemplates();
    this.subs.add(
      this.source.pipe(takeUntil(this.isEditorLoaded$)).subscribe(val => {
        if (this.design) this.loadDesign(this.design);
      }),
    );
    this.getInternalWebsitePage();
    this.getAvailableWebsitePage();
  }

  editSelectedPage() {
    if (this.selectedPageUnJson) this.loadDesign(this.selectedPageUnJson);
    this.isInternalPageEdit = true;
    setTimeout(() => {
      if (this.isInternalPageEdit) {
        this.myStepper.selectedIndex = 0;
        if(this.pageName != 'home'){
          this.myStepper.selectedIndex = 1;
        }
      }
    }, 100);
  }

  getUntemplates() {
    var pageName;
    if (['home', 'about', 'contact', 'mediagallery','privacypolicy','termsofservice'].includes(this.pageName)) {
      pageName = this.pageName;
    } else {
      pageName = 'others';
    }
    this.isInternalSiteLoading = true;
    this.selectedPageUnTemplates = undefined;
    this.selectedPageUntotal = undefined;
    this.subs.add(
      this._externalWebsiteService.getTemplateListByPageType(pageName, 0, 5).subscribe((result: any) => {
        if (result.data.length > 0) {
          this.selectedPageUnTemplates = result.data;
          this.selectedPageUntotal = result.total;
        }
        this.isInternalSiteLoading = false;
      }),
    );
  }

  getInternalWebsitePage() {
    const pageName = this.pageName;
    this.isAddWebSiteObject = true;
    this.subs.add(
      this._externalWebsiteService.getInternalWebsitePage(pageName).subscribe(
        (result: any) => {
          if (!result) {
            this.reset();
            return;
          }
          this.isAddWebSiteObject = false;
          if (result.unhtml != '') {
            this.selectedPageUhhtml = this.sanitizer.bypassSecurityTrustHtml(result.unhtml);
          } else {
            this.selectedPageUhhtml = '';
          }
          if (!this.isPagePrivacyPolicyOrTermsOfService || result.unhtml !== '' && (result.unjson === null || result.unjson === '') && !result.isExternalLink) {
            this.isRawTemplateSelected = true;
            this.htmlEditorVal = result.unhtml;
          } else {
            this.isRawTemplateSelected = false;
          }
          this.selectedPageUnJson = result.unjson;
          this.pageTitle = result.title;
          this.pageName = result.name;
          this.url = result.url;
          this.isPublished = result.isPublished;
          this.isShowNavigation = result.isShowNavigation;
          this.isShowFooter = result.isShowFooter;
          this.isExternalWebsiteUrl = result.isExternalLink;
          this.externalLinkValue = result.externalLink;
          this.pageId = result._id;
          this.header = result.header;
          this.subHeader = result.subHeader;
          this.footer = result.footer;
          this.externalWebsiteForm = new FormGroup({
            externalLinkValue: new FormControl(this.externalLinkValue, [Validators.required, Validators.pattern(this.domainRegex)]),
          });
        },
        error => {
          this.reset();
        },
      ),
    );
  }

  private reset() {
    this.selectedPageUhhtml = '';
    this.selectedPageUnJson = '';
    this.url = '';
    this.isExternalWebsiteUrl = false;
    this.externalLinkValue = '';
    this.pageId = '';
    this.header = '';
    this.subHeader = '';
    this.footer = '';
    this.externalWebsiteForm = new FormGroup({
      externalLinkValue: new FormControl(this.externalLinkValue, [Validators.required, Validators.pattern(this.domainRegex)]),
    });
  }

  templateSelect(design, stepper: MatStepper) {
    this.isTemplateSkip = false;
    this.isRawTemplateSelected = false;
    stepper.next();

    this.emptyForm = false;
    if (design === undefined) {
      this.emptyForm = true;
      this.emailEditor.editor.loadBlank({
        backgroundColor: '#F8F8FF',
      });
    } else if (design === 'raw') {
      this.isRawTemplateSelected = true;
    } else {
      this.loadDesign(design);
    }
  }

  loadDesign(design) {
    this.design = design;
    this.editorLoaded();
  }

  editorLoaded() {
    if (this.emailEditor && this.emailEditor.editor) {
      this.isEditorLoaded$.next(false);
      this.emailEditor.editor.loadDesign(this.design);
      this.getHtml();
    }
  }

  getHtml() {
    if (this.emailEditor && this.emailEditor.editor) {
      this.emailEditor.editor.exportHtml(data => {
        this.editorHtml = this.sanitizer.bypassSecurityTrustHtml(data.html);
        this._changeDetectorRef.detectChanges();
      });
    }
  }

  saveDesign() {
    let payload: any = {
      unhtml: '',
      unjson: '',
      pageType: this.pageName,
      name: '',
    };
    this.emailEditor.editor.exportHtml(data => {
      payload.unhtml = data.html;
      this.emailEditor.editor.saveDesign(data => {
        payload.unjson = data;
        this.openTemplateDialog(payload);
      });
    });
  }

  openTemplateDialog(data) {
    this.dialogRef = this._matDialog.open(ExternalWebsiteAddTemplateDlgComponent, {
      panelClass: 'dlg-contct-div',
      disableClose: false,
      height: '285px',
      width: '450px',
      data: data,
    });
    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: any) => {
        if (!response) {
          return;
        }
        if (!['home', 'about', 'contact','privacypolicy','termsofservice'].includes(response.pageType)) {
          response.pageType = 'others';
        }
        // this.SpinnerService.show();
        this.subs.add(
          this._externalWebsiteService.addWebsiteTemplate(response).subscribe(
            (result: any) => {
              this.toastr.success('Added template successfully');
              // this.SpinnerService.hide();
              this.getUntemplates();
            },
            error => {
              this.toastr.error(error.message);
              // this.SpinnerService.hide();
            },
          ),
        );
      }),
    );
  }

  addUpdateInternalWebsite(isHtmlUpdate = true) {
    var pageName = this.pageName;
    const pageId = this.pageId;
    this.isInternalSubmitLoading = true;
    let payload: any = {};
    // if (this.emptyForm == true && isHtmlUpdate == true) {
    //   this.toastr.error("Please select or create a valid template");
    //   this.isInternalSubmitLoading = false;
    //   return;
    // }
    if (this.isRawTemplateSelected) {
      payload = {
        unhtml: this.htmlEditorVal,
        unjson: '',
        title: this.pageTitle,
        url: this.pageTitle,
        isExternalLink: this.isExternalWebsiteUrl,
        header: this.header,
        subHeader: this.subHeader,
        footer: this.footer,
        name: this.pageName,
        externalLink: this.externalWebsiteForm.get('externalLinkValue').value,
      };
      isHtmlUpdate = false;
    } else if (this.externalWebsiteForm === undefined) {
      payload = {
        unhtml: isHtmlUpdate ? '' : this.selectedPageUhhtml ? this.selectedPageUhhtml.changingThisBreaksApplicationSecurity : '',
        unjson: this.selectedPageUnJson ? this.selectedPageUnJson : '',
        title: this.pageTitle,
        url: this.pageTitle,
        isExternalLink: this.isExternalWebsiteUrl,
        header: this.header,
        subHeader: this.subHeader,
        footer: this.footer,
        name: this.pageName,
        externalLink: this.externalWebsiteForm.get('externalLinkValue').value,
      };
    } else {
      payload = {
        unhtml: isHtmlUpdate ? '' : this.selectedPageUhhtml ? this.selectedPageUhhtml.changingThisBreaksApplicationSecurity : '',
        unjson: this.selectedPageUnJson ? this.selectedPageUnJson : '',
        title: this.pageTitle,
        url: this.pageTitle,
        isExternalLink: this.isExternalWebsiteUrl,
        header: this.header,
        subHeader: this.subHeader,
        footer: this.footer,
        name: this.pageTitle,
        externalLink: this.externalWebsiteForm.get('externalLinkValue').value,
      };
    }

    if (isHtmlUpdate) {
      this.emailEditor.editor.exportHtml(data => {
        payload.unhtml = data.html;
        payload.body = data.chunks.body;
        payload.js = data.chunks?.js;
        payload.style = data.chunks?.css;
        payload.fonts = data.chunks?.fonts;
        this.emailEditor.editor.saveDesign(data => {
          payload.unjson = data;
          this.addInternalWebsiteCall(pageId, pageName, payload);
        });
      });
    } else {
      this.addInternalWebsiteCall(pageId, pageName, payload);
    }
  }

  publishPage($event: MatSlideToggleChange){
    var pageName = this.pageName;
    const pageId = this.pageId;
    let payload = {
      isPublished: $event.checked,
    }
    this.isPublished = $event.checked
    this.addInternalWebsiteCall(pageId, pageName,payload)
    if (this.isPublished === true) {
      this.toastr.success(pageName + ' Page is Published.');
    } else {
      this.toastr.success(pageName + ' Page is Private.');
    }
  }

  addInternalWebsiteCall(pageId, pageName, payload) {
    if (this.isAddWebSiteObject) {
      this.subs.add(
        this._externalWebsiteService.addInternalWebsitePage(pageName, payload).subscribe(
          result => {
            this.toastr.success(pageName + ' page added.');
            this.pageName = this.pageName;
            this.getInternalWebsitePage();
            this.isInternalPageEdit = false;
            this.isInternalSubmitLoading = false;
            this.isRawTemplateSelected = false;
            this.getAvailableWebsitePage();
          },
          error => {
            this.isInternalSubmitLoading = false;
            this.isRawTemplateSelected = false;
            this.toastr.error(error.message);
          },
        ),
      );
    } else {
      this.subs.add(
        this._externalWebsiteService.updateInternalWebsitePage(pageId, payload).subscribe(
          result => {
            this.getAvailableWebsitePage();
            this.pageName = this.pageName;
            this.getInternalWebsitePage();
            this.isInternalPageEdit = false;
            this.isInternalSubmitLoading = false;
            this.isRawTemplateSelected = false;
          },
          error => {
            this.toastr.error(error.message);
            this.isRawTemplateSelected = false;
          },
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cancelAddEditInternalPage() {
    this.isInternalPageEdit = false;
  }

  openWebsiteSettingsPage() {
    this.router.navigate(['/settings/website']);
  }
 
  setSelectedInternalWebsitePage(selectedPage: any): void {
    if (selectedPage === 'calendar') {
      this.getCalendarComponent();
    }

    if (selectedPage === 'events') {
      this.getEventsComponent();
    }

    if (selectedPage === 'courses') {
      this.getCoursesComponent();
    }

    if (selectedPage === 'merchandise') {
      this.getMerchandiseComponent();
    }

    if (selectedPage === 'forms') {
      this.getFormsComponent();
    }
    if (selectedPage === 'donations'){
      this.getDonationsComponent();
    }
    if (selectedPage === 'mediagallery'){
      this.getEventsListForGalleryComponent();
    }

    // if (isPagePPOrTOS()){
    // }
    const selectedStandardPageLocalData = AppConstants.defaultStandardWebsitePages.get(selectedPage);
    const selectedInternalPageLocalData = AppConstants.defaultInternalWebsitePages.get(selectedPage);
    this.pageName = selectedPage;

    if (selectedStandardPageLocalData !== undefined) {
      this.isDefaultWebsitePagesSelected = false;
      this.pageTitle = selectedStandardPageLocalData.title;
      this.url = selectedStandardPageLocalData.url;
    } else {
      this.isDefaultWebsitePagesSelected = true;
      if (selectedInternalPageLocalData !== undefined) {
        this.pageTitle = selectedInternalPageLocalData.title;
        this.url = selectedInternalPageLocalData.url;
      }
    }
    this.getUntemplates();
    this.getInternalWebsitePage();
    this.myStepper.selectedIndex = 0;
    this.isInternalPageEdit = false;
    this.isExternalWebsiteUrl = false;
  }

  getAvailableWebsitePage() {
    let defaultInternalWebsitePagesMap = new Map([]);

    this.subs.add(
      this._externalWebsiteService.getAvailableWebsitePages().subscribe(
        (result: any) => {
          this.availablePageList = [];

          AppConstants.defaultInternalWebsitePages.forEach((value, key) => {
            defaultInternalWebsitePagesMap.set(value.name, value);
          });

          AppConstants.defaultStandardWebsitePages.forEach((value, key) => {
            defaultInternalWebsitePagesMap.set(value.name, value);
          });

          result.forEach((currentValue, index) => {
            const insertedMenuData: any = defaultInternalWebsitePagesMap.get(currentValue.name);
            if (!insertedMenuData) {
              currentValue.isStandadPages = false;
              currentValue.sequence = currentValue.sequence;
              defaultInternalWebsitePagesMap.set(currentValue.name, currentValue);
            } else {
              defaultInternalWebsitePagesMap.get(currentValue.name)['id'] = currentValue.id;
              defaultInternalWebsitePagesMap.get(currentValue.name)['sequence'] = currentValue.sequence;
              defaultInternalWebsitePagesMap.get(currentValue.name)['isPublished'] = currentValue.isPublished;
            }
          });
          this.availablePageList = Array.from(defaultInternalWebsitePagesMap.values());
          this.availablePageList.sort((a, b) => {
            if (a.sequence < b.sequence) {
              return -1;
            }
          });
        },
        error => {},
      ),
    );
  }

  openAddNewWebsitePage() {
    const name = Math.floor(1000 + Math.random() * 9000).toString();

    this.isAddWebSiteObject = true;
    const payload = {
      title: name,
      url: name,
      name: name,
      isExternalLink: false,
      unhtml: '',
      unjson: '',
    };
    this.pageName = name;
    this.pageTitle = name;
    this.addInternalWebsiteCall(null, name, payload);
    this.setSelectedInternalWebsitePage(name);
  }
  deletePage() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      panelClass: 'delete-course-dialog',
      disableClose: true,
    });

    this.confirmDialogRef.componentInstance.confirmTitle= 'Delete';
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this page?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subs.add(
          this._externalWebsiteService.deleteWebsitePage(this.pageId).subscribe(
            result => {
              this.toastr.success(this.pageName + ' is deleted successfully.');
              this.pageName = 'home';
              this.pageTitle = 'Home';
              this.getAvailableWebsitePage();
              this.getInternalWebsitePage();
            },
            error => {
              this.toastr.error(error.errors.message);
            },
          ),
        );
      }
      this.confirmDialogRef = null;
    });
  }
  private getCoursesComponent() {
    this.coursesComponentInjector = import('../../../../public/courses/courselist/courses.component').then(({ CoursesComponent }) => CoursesComponent);
  }

  private getEventsComponent() {
    this.eventsComponentInjector = import('../../../../public/events/eventlist/events.component').then(({ EventsComponent }) => EventsComponent);
  }
  private getEventsListForGalleryComponent() {
    this.eventGalleryComponentInjector = import('../../../../public/events/eventlistforgallery/eventlistforgallery.component').then(({ EventlistforgalleryComponent }) => EventlistforgalleryComponent);
  }

  private getCalendarComponent() {
    this.calenderComponentInjector = import('../../../../public/calendar/schedule-list/schedule-listview.component').then(({ ScheduleListViewComponent }) => ScheduleListViewComponent);
  }

  private getFormsComponent() {
    this.formsComponentInjector = import('../../../custom-forms/custom-forms-list/custom-forms-list.component').then(({ CustomFormsListComponent }) => CustomFormsListComponent);
  }

  private getMerchandiseComponent() {
    this.merchandiseComponentInjector = import('../../../products/product-list/product-list.component').then(({ ProductListComponent }) => ProductListComponent);
  }
  private getDonationsComponent(){
    this.donationComponentInjector = import('../../../../admin/donations/donations-list/donations-list.component').then(({DonationsListComponent}) => DonationsListComponent);
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    // Programatic content selection example
    editor.setSelection({
      startLineNumber: 1,
      startColumn: 1,
      endColumn: 50,
      endLineNumber: 3,
    });
  }

  registerMonacoCustomTheme() {
    monaco.editor.defineTheme('myCustomTheme', {
      base: 'vs-dark', // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: 'comment',
          foreground: 'ffa500',
          fontStyle: 'italic underline',
        },
        { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
        { token: 'comment.css', foreground: '0000ff' }, // will inherit fontStyle from `comment` above
      ],
      colors: {},
    });
  }

  registerMonacoJsonSchemaValidator() {
    this.modelUri = monaco.Uri.parse('a://b/city.json');
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'http://myserver/city-schema.json', // id of the first schema
          fileMatch: ['city*.json'],
          schema: {
            type: 'object',
            additionalProperties: false,
            properties: {
              city: {
                enum: ['Paris', 'Berlin', 'Boardman'],
              },
              country: {
                enum: ['France', 'Germany', 'United States'],
              },
              population: {
                type: 'integer',
              },
            },
          },
        },
      ],
    });
  }
  openPublishUnpublishDialog() {
    this.dialogRef = this._matDialog.open(InternalWebsitePublishUnpublishDlgComponent, {
      disableClose: true,
      data: { pageId: this.pageId, isShowFooter: this.isShowFooter, isShowNavigation: this.isShowNavigation },
      width: '450px',
    });
  }

  public get isPagePrivacyPolicyOrTermsOfService(): boolean{
    return this.pageName.replace(/\s+/g, '').toLowerCase() === 'termsofservice' || this.pageName.replace(/\s+/g, '').toLowerCase() === 'privacypolicy';
  }
  
  public get isNonDeletable(): boolean {
    return this.mandatoryPages.includes(this.pageName);
  }
}
