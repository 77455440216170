import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CourseModel } from 'app/main/shared/models/course.model';
import { ClassModel } from 'app/main/shared/models/class.model';
import { ClassSummary } from 'app/main/shared/models/class.summary.model';
import { AttendeeSummary } from 'app/main/shared/models/attendee.summary.model';
import { AttendeeModel } from 'app/main/shared/models/attendee.model';
import { CourseSummary } from 'app/main/shared/models/course.summary.model';
import { PricingModel } from 'app/main/shared/models/pricing.model';
import { LessonModel } from 'app/main/shared/models/lesson.model';
import { SectionModel } from '../../shared/models/section.model';
import { QuizModel } from 'app/main/shared/models/quiz.model';
import { QuizSummary } from 'app/main/shared/models/quiz.summary.model';
import { BunnyUploadService } from 'app/main/shared/services/bunny-upload.service';
import { result } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AppLoadService } from 'app/core/services/appload.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  baseUrl = environment.apiBaseUrl;
  public courseSummary: Observable<any>;
  public course: Observable<any>;
  public courseData: Observable<any>;
  public lessons: Observable<any>;
  public classSummary: Observable<any>;
  public attendeeSummary: Observable<any>;
  public courseTests: Observable<any>;
  public courseTestsSummary: Observable<any>;
  public coursePricing: Observable<any>;
  public courseSection: Observable<any>;
  public courseSections: Observable<any>;

  private scormChapterListSubject = new BehaviorSubject<any[]>([]);
  private scormMomentListSubject = new BehaviorSubject<any[]>([]);
  private scormTestListSubject = new BehaviorSubject<any[]>([]);
  private _configSubject: BehaviorSubject<any>;
  private _configSubjectCourse: BehaviorSubject<any>;
  private _configSubjectLessons: BehaviorSubject<any>;
  private _configSubjectProgram: BehaviorSubject<any>;
  private _configSubjectAttendees: BehaviorSubject<any>;
  private _configSubjectCourseTests: BehaviorSubject<any>;
  private _configSubjectCoursePricing: BehaviorSubject<any>;
  private _configSubjectSection: BehaviorSubject<any>;
  private _configSubjectSections: BehaviorSubject<any>;
  private _configSubjectCourseTestsSummary: BehaviorSubject<any>;

  private _activitySubject = new BehaviorSubject<any>(null);
  private _assessmentSubject = new BehaviorSubject<any>(null);

  public get activity$(): Observable<any> {
    return this._activitySubject.asObservable();
  }

  public set activity(activity: any) {
    this._activitySubject.next(activity);
  }

  public get assessment$(): Observable<any> {
    return this._assessmentSubject.asObservable();
  }

  public set assessment(assessment: any) {
    this._assessmentSubject.next(assessment);
  }

  public get chapterList$(): Observable<any> {
    return this.scormChapterListSubject.asObservable();
  }

  public get momentList$(): Observable<any> {
    return this.scormMomentListSubject.asObservable();
  }

  public get testList$(): Observable<any> {
    return this.scormTestListSubject.asObservable();
  }

  constructor(
    private http: HttpClient,
    private _bunnyUploadService: BunnyUploadService,
    private _appLoadService: AppLoadService,
    private _toastr: ToastrService,
  ) {
    this._configSubject = new BehaviorSubject(this.courseSummary);
    this._configSubjectCourseTestsSummary = new BehaviorSubject(this.courseTestsSummary);
    this._configSubjectCourse = new BehaviorSubject(this.course);
    this._configSubjectProgram = new BehaviorSubject(this.classSummary);
    this._configSubjectAttendees = new BehaviorSubject(this.attendeeSummary);
    this._configSubjectCourseTests = new BehaviorSubject(this.courseTests);
    this._configSubjectCoursePricing = new BehaviorSubject(this.coursePricing);
    this._configSubjectLessons = new BehaviorSubject(this.lessons);
    this._configSubjectSection = new BehaviorSubject(this.courseSection);
    this._configSubjectSections = new BehaviorSubject(this.courseSections);
  }

  get getCourses(): any | Observable<any> {
    return this._configSubject.asObservable();
  }

  get getCourseTestsSummery(): any | Observable<any> {
    return this._configSubjectCourseTestsSummary.asObservable();
  }
  get getProgram(): any | Observable<any> {
    return this._configSubjectProgram.asObservable();
  }

  get getCourse(): any | Observable<any> {
    return this._configSubjectCourse.asObservable();
  }

  get getAttendees(): any | Observable<any> {
    return this._configSubjectAttendees.asObservable();
  }

  get getCourseTests(): any | Observable<any> {
    return this._configSubjectCourseTests.asObservable();
  }

  get getCoursePricing(): any | Observable<any> {
    return this._configSubjectCoursePricing.asObservable();
  }

  get getLessons(): any | Observable<any> {
    return this._configSubjectLessons.asObservable();
  }

  get getSection(): any | Observable<any> {
    return this._configSubjectSection.asObservable();
  }

  get getSections(): any | Observable<any> {
    return this._configSubjectSections.asObservable();
  }

  private convertCourseListToModel(obj: any): any {
    return new CourseModel(obj);
  }

  private convertProgramListToModel(obj: any, _imagePrefixPath: string): any {
    return new ClassModel(obj, _imagePrefixPath);
  }

  private convertAttendeesListToModel(obj: any): any {
    return new AttendeeModel(obj);
  }

  private convertCourseTestListToModel(obj: any): any {
    return new QuizModel(obj);
  }

  private convertCoursePricingListToModel(obj: any): any {
    return new PricingModel(0, obj);
  }

  private convertLessonListToModel(obj: any): any {
    return obj.map(x => new LessonModel(x));
  }

  private convertCourseSectionToModel(obj: any): any {
    return new SectionModel(obj);
  }

  addVideoScormInfo(_id: string, payload: any) {
    const url = `${environment.apiBaseUrl}/edmedia/video/${_id}`;
    return this.http.put(url, payload).pipe(
      map((result: any) => {
        this.scormChapterListSubject.next(result?.video?.chapters || []);
        this.scormMomentListSubject.next(result?.video?.moments || []);
        return result?.video;
      }),
      catchError(err => {
        this._toastr.error(err.error.errors.message);
        return of(null);
      }),
    );
  }

  addScormTests(_id: string, data: any[]): Observable<any> {
    const url = `${environment.apiBaseUrl}/edmedia/scorm/${_id}`;
    return this.http.put(url, { data }).pipe(
      map((result:any)=>{
        this.scormTestListSubject.next(result);
        return result;
      }),
      catchError((err)=>{
        this._toastr.error(err.error.errors.message);
        return of(null);
      })
    ) 
  }

  deleteScormTest(_id: string) {
    const url = `${environment.apiBaseUrl}/edmedia/scorm/${_id}`;
    return this.http.delete(url);
  }

  getScormTests(_id: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/edmedia/scorm/${_id}`;
    return this.http.get(url);
  }

  setCourse(courseSummary: any, isNotify: boolean) {
    this.courseSummary = courseSummary;
    if (isNotify) {
      this._configSubject.next(this.courseSummary);
    }
  }

  setCourseTestsSummary(courseTestsSummary: any, isNotify: boolean) {
    this.courseTestsSummary = courseTestsSummary;
    if (isNotify) {
      this._configSubjectCourseTestsSummary.next(this.courseTestsSummary);
    }
  }

  setLessons(lessons: any, isNotify: boolean) {
    this.lessons = lessons;
    if (isNotify) {
      this._configSubjectLessons.next(this.lessons);
    }
  }

  setCourseSection(section: any, isNotify: boolean) {
    this.courseSection = section;
    if (isNotify) {
      this._configSubjectSection.next(this.courseSection);
    }
  }

  setCourseSections(sections: any, isNotify: boolean) {
    this.courseSections = sections;
    if (isNotify) {
      this._configSubjectSections.next(this.courseSections);
    }
  }

  setCourseObj(course: any, isNotify: boolean) {
    this.course = course;
    if (isNotify) {
      this._configSubjectCourse.next(this.course);
    }
  }

  setPrograms(classSummary: any, isNotify: boolean) {
    this.classSummary = classSummary;
    if (isNotify) {
      this._configSubjectProgram.next(this.classSummary);
    }
  }

  setAttendees(attendeeSummary: any, isNotify: boolean) {
    this.attendeeSummary = attendeeSummary;
    if (isNotify) {
      this._configSubjectAttendees.next(this.attendeeSummary);
    }
  }

  setCourseTests(courseTests: any, isNotify: boolean) {
    this.courseTests = courseTests;
    if (isNotify) {
      this._configSubjectCourseTests.next(this.courseTests);
    }
  }

  setCoursePricing(pricing: any, isNotify: boolean) {
    this.coursePricing = pricing;
    if (isNotify) {
      this._configSubjectCoursePricing.next(this.coursePricing);
    }
  }

  private getCourseUrl(guId) {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory/${guId}`;
    let options = {
      isShowDetails: true,
      isShowDisabled: false,
      isShowTemplate: true,
    };
    let args = [];
    if (options.isShowDetails) {
      args.push('details');
    }
    if (options.isShowDisabled) {
      args.push('disabled');
    }
    if (options.isShowTemplate) {
      args.push('template');
    }
    if (args.length) {
      apiUrl += '?include=' + args.join(',');
    }
    return apiUrl;
  }

  getCourseDetail(guId, isNotify, courseBatchId = ''): Observable<any> {
    let apiUrl = this.getCourseUrl(guId);
    apiUrl += courseBatchId ? `&courseBatchId=${courseBatchId}` : '';
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        this.courseData = result.data;
        this.course = this.convertCourseListToModel(result.data);
        this.setCourseObj(this.course, isNotify);
        if (result.data.courseSections != null && result.data.courseSections.length != 0) {
          this.bindCourseAndLesson(result.data.courseSections[0]);
          this.bindCourseSections(result.data.courseSections);
        } else {
          this.bindCourseAndLesson({});
          this.bindCourseSections([]);
        }
        return this.course;
      }),
    );
  }

  bindCourseSections(sections: any) {
    let res = [];
    sections.forEach(section => {
      res.push(this.convertCourseSectionToModel(section));
    });
    this.setCourseSections(res, true);
  }

  bindCourseAndLesson(section: any) {
    if (section?.chapters) {
      this.lessons = this.convertLessonListToModel(section.chapters);
    }
    this.setLessons(this.lessons, true);
    this.courseSection = this.convertCourseSectionToModel(section);
    this.setCourseSection(this.courseSection, true);
  }

  getCourseObject(guId): Observable<any> {
    let apiUrl = this.getCourseUrl(guId);
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result.data;
      }),
    );
  }

  getCourseList(start: any, max: any, searchText: any, isNotify: boolean): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory?start=${start}&max=${max}`;
    if (searchText) {
      apiUrl += `&search=${searchText}`;
    }

    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let courseList = result.data.map(obj => {
          return this.convertCourseListToModel(obj);
        });
        this.setCourse(new CourseSummary(result.recordsTotal, courseList), isNotify);
        return this.courseSummary;
      }),
    );
  }

  getAllCourseList(start: any, length: any, searchText: any, isNotify: boolean): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory?type=service&start=${start ?? 0}&max=${length ?? 50}&isShowArchived=true&include=stats`;
    if (searchText) {
      apiUrl += `&search=${searchText}`;
    }

    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let courseList = result.data.map(obj => {
          return this.convertCourseListToModel(obj);
        });
        this.setCourse(new CourseSummary(result.recordsTotal, courseList), isNotify);
        return this.courseSummary;
      }),
    );
  }

  updateCourseSequence(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/snode/icategory/sequence`, payload);
  }

  getCourseForItems(): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/list?type=service`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result.map(obj => {
          return this.convertCourseListToModel(obj);
        });
      }),
    );
  }

  getAllCategories(): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory/coursenames/`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getCourseProgress(guId, start, length, searchText): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/courseAttendee/courseProgress/${guId}?draw=1&start=${start}&length=${length}&search%5Bvalue%5D=${searchText}`;
    return this.http.get(url);
  }

  getPrograms(guId: any, ishowAll: any, start: any, length: any, _imagePrefixPath: any, isNotify: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}/rules/?start=${start}&length=${length}`;
    if (ishowAll) {
      apiUrl = `${apiUrl}&include=all`;
    }
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let programs = result.data.map(obj => {
          return this.convertProgramListToModel(obj, _imagePrefixPath);
        });
        this.setPrograms(new ClassSummary(result.recordsTotal, programs), isNotify);
        return this.classSummary;
      }),
    );
  }

  getAttendeesData(guId: any, isNotify: any, start: any, length: any, searchText: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}/attendee?draw=1&start=${start}&length=${length}&search%5Bvalue%5D=${searchText}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let data = result.data.map(obj => {
          return this.convertAttendeesListToModel(obj);
        });
        this.setAttendees(new AttendeeSummary(result.recordsTotal, data), isNotify);
        return this.attendeeSummary;
      }),
    );
  }

  getItemAttendeesData(guId: any, isNotify: any, start: any, length: any, searchText: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/course/${guId}/attendee?offset=${start}&max=${length}&search=${searchText}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let data = result.data.map(obj => {
          return this.convertAttendeesListToModel(obj);
        });
        this.setAttendees(new AttendeeSummary(result.recordsTotal, data), isNotify);
        return this.attendeeSummary;
      }),
    );
  }

  getProductAttendeesData(guId: any, productType: any, start: number , pageSize: number, searchText: string= '', batchId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/product/attendee?productId=${guId}&productType=${productType}&start=${start}&max=${pageSize}&search=${searchText}`;
    if (batchId) {
      apiUrl += `&batchId=${batchId}`;
    }
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  deleteAttendee(cId: any, productId: any, productType: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/product/attendee/${cId}?productId=${productId}&productType=${productType}`;
    return this.http.delete(apiUrl).pipe(
      map(result => {
        return result;
      }),
    );
  }

  enrolledAttendee(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/product/attendee`;
    return this.http.post(apiUrl, payload).pipe(
      map(result => {
        return result;
      }),
    );
  }

  enrollPbundleCourse(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/pbundle/selfenroll`;
    return this.http.post(apiUrl, payload);
  }

  getMembershipsData(courseId: any, contactId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/course/${courseId}/attendee/${contactId}/memberships`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  updateAttendeeMemberships(data: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/course/${data.courseId}/attendee/${data.contactId}`;
    return this.http.put(apiUrl, payload, { params: { attendeeId: data.attendee.attendeeId } }).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  itemCategoryAttendee(guId: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/course/${guId}/attendee`;
    return this.http.post(apiUrl, payload).pipe(
      map(result => {
        return result;
      }),
    );
  }

  deleteBulkItemCategoryAttendee(guId: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/course/${guId}/attendee`;
    return this.http.put(apiUrl, payload).pipe(
      map(result => {
        return result;
      }),
    );
  }

  deleteBulkCategoryAttendee(guId: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}/bulkAttendeeDelete`;
    return this.http.put(apiUrl, payload).pipe(
      map(result => {
        return result;
      }),
    );
  }

  getAssessmentResultsById(contactId, max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edassesment/assesmentresult?contactId=${contactId}&max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getAssessmentResultsByOrgId(max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edassesment/assesmentresult?max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getAssessmentResultsByAssessmentId(assesmentId, max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edassesment/assesmentresult?assesmentId=${assesmentId}&max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getAssessmentEnquiryList(max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/client/ms/enquiry?max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getAssessmentEnquiry(id): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/client/ms/enquiry/${id}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  updateEnquiry(id, payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/client/ms/enquiry/${id}`;
    return this.http.put(apiUrl, payload).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getActiviytResultsById(contactId, max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/activityresult?contactId=${contactId}&max=${max}&start=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getActiviytResultsByOrgId(max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/activityresult?max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  updateAssessment(payload: any) {
    let apiUrl = `${this.baseUrl}/edassesment/assesmentresult/${payload._id}`;
    return this.http.put(apiUrl, payload);
  }

  getTestList(courseId, max, offset, isNotify): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/quiz?courseId=${courseId}&max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        let courseTests = result.data.map(obj => {
          return this.convertCourseTestListToModel(obj);
        });
        this.setCourseTestsSummary(new QuizSummary(result.total, courseTests), isNotify);
        return this.courseTestsSummary;
      }),
    );
  }

  getEmail(guId) {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory/${guId}/email`;
    return this.http.get(apiUrl);
  }

  getActivityList(courseId, start , pageSize, search): Observable<any> {
    let url = `${environment.apiBaseUrl}/edtest/activity/activityList/${courseId}?&max=${pageSize}&start=${start}&search=${search}`;
    return this.http.get(url);
  }

  getActivityResults(activityId, courseId, max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/activityResult?activityId=${activityId}&courseId=${courseId}&max=${max}&offset=${offset}`;
    return this.http.get(apiUrl);
  }

  getActivityCourseAttendee(chapterId, courseId, pageSize:number, pageIndex: number =0): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/courseAttendee?chapterId=${chapterId}&courseId=${courseId}&max=${pageSize}&start=${pageIndex}`;
    return this.http.get(apiUrl);
  }

  getTestResults(paperId): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/paper/${paperId}/result`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result.data;
      }),
    );
  }

  getTestResultsById(contactId, max, offset, operations): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/result?contactId=${contactId}&max=${max}&offset=${offset}&sort=${operations.sort}&order=${operations.order}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getQuizResultsById(operations): Observable<any> {
    let url = `${environment.apiBaseUrl}/edtest/result`;
    return this.http.get(url, { params: operations });
  }

  getTestResultsByOrgId(max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/result?max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getTestCharts(param): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/result/report?contactId=${param}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getResultByContact(contactId, quizId): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/result/test?contactId=${contactId}&quizId=${quizId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getResults(paperId, courseId, max, offset): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/result?quizId=${paperId}&courseId=${courseId}&max=${max}&offset=${offset}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  getEvtCategory(): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory/event`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
    );
  }

  addEvtCategory(payload) {
    let apiUrl = `${environment.apiBaseUrl}/snode/evtcategory`;
    return this.http.post(apiUrl, payload);
  }

  getCoursePricingList(guId: any, isNotify: boolean, isVisible: boolean): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}/items`;
    apiUrl += isVisible ? '?isVisible=true' : '?include=disabled';
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        this.coursePricing = null;
        if (result && result.memberships) {
          this.coursePricing = result.memberships.map(obj => {
            return this.convertCoursePricingListToModel(obj);
          });
        }
        this.setCoursePricing(this.coursePricing, isNotify);
        return this.coursePricing;
      }),
    );
  }

  categoryAttendee(guId: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}/attendee`;
    return this.http.post(apiUrl, payload).pipe(
      map(result => {
        return result;
      }),
    );
  }

  deleteCourse(guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}`;
    return this.http.delete(apiUrl).pipe(
      tap(res => console.log('HTTP response: delete course', res)),
      map(data => {
        return data;
      }),
    );
  }

  deletePutCourse(guId: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/itemCategory/${guId}`;
    return this.http.put(apiUrl, payload).pipe(
      tap(res => console.log('HTTP response: delete course', res)),
      map(data => {
        return data;
      }),
    );
  }

  deleteBatch(guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/rest/courseBatch/${guId}`;
    return this.http.delete(apiUrl).pipe(
      tap(res => console.log('HTTP response: delete course', res)),
      map(data => {
        return data;
      }),
    );
  }

  getDonationList(): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/donation?start=0&max=50&isShowAll=true`;
    return this.http.get(apiUrl).pipe(
      tap(res => console.log('HTTP response: donations', res)),
      map(data => {
        return data;
      }),
    );
  }

  uploadFile(cloudinary_prefix, upload_preset, selectedFile: File) {
    // var data = {
    //   upload_preset: cloudinary_preset,
    //   folder: orgId,
    //   tags: orgId,
    //   file: file,
    //   context: "photo=" + new Date().getTime()
    // };
    let fd = new FormData();
    fd.append('upload_preset', upload_preset);
    fd.append('folder', 'm300');
    fd.append('tags', 'm300');
    fd.append('context', 'photo=' + new Date().getTime());
    fd.append('image', selectedFile, selectedFile.name);
    fd.append('file', selectedFile);
    let headers = new HttpHeaders({
      Authorization: undefined,
    });
    let options = { headers: headers };
    console.log('cloudinary_prefix', cloudinary_prefix);
    console.log('file', fd);

    console.log('selectedFile', selectedFile);
    let apiUrl = `https://api.cloudinary.com/v1_1/${cloudinary_prefix}/upload`;
    return this.http.post(apiUrl, fd, options).pipe(
      tap(res => console.log('HTTP response: donations', res)),
      map(data => {
        return data;
      }),
    );
  }

  addNewSection(section: any) {
    let apiUrl = `${this.baseUrl}/rest/itemCategory/${section.course}/section`;
    return this.http.post(apiUrl, section).pipe(
      map(data => {
        return data;
      }),
    );
  }

  addNewLessons(lesson: any) {
    let apiUrl = `${this.baseUrl}/rest/itemCategory/${lesson.course}/section/${lesson.section}/chapter`;
    return this.http.post(apiUrl, lesson).pipe(
      map(data => {
        return data;
      }),
    );
  }

  addNewActivity(lesson) {
    let apiUrl = `${this.baseUrl}/edtest/activity`;
    return this.http.post(apiUrl, lesson).pipe(
      map(data => {
        return data;
      }),
    );
  }

  deleteActivity(activityId) {
    let apiUrl = `${this.baseUrl}/edtest/activity/${activityId}`;
    return this.http.delete(apiUrl);
  }

  submitTest(test: any) {
    return this.http.post(`${environment.apiBaseUrl}/edtest/quiz`, test).pipe(
      map((result: any) => {
        this._bunnyUploadService.isLoading = { description: false };
        this._assessmentSubject.next(result.data);
        return result.data;
      }),
      catchError(error => {
        this._toastr.error(error.error.errors.message);
        return of(null);
      }),
    );
  }

  getQuizById(quizId, isNotify = true): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/edtest/quiz/${quizId}`).pipe(
      map(res => {
        if (isNotify) {
          this._assessmentSubject.next(res);
        }
        return res;
      }),
    );
  }

  addQuizAttendee(quizId: string, contactId: string): Observable<any> {
    const tenantId = this._appLoadService.tenantConfig.tenantId;
    const payload = {
      quizId,
      contactId,
      tenantId,
    };

    return this.http.post(`${environment.apiBaseUrl}/edtest/qattendee`, payload);
  }

  // getQuizAttendee(quizId: string,productType: any, isNotify = true,): Observable<any> {
  //   const params = { quizId,productType};
  //   return this.http.get(`${environment.apiBaseUrl}/edtest/quiz/attendee`, { params }).pipe(
  //     map(res => {
  //       if (isNotify) {
  //         this._assessmentSubject.next(res);
  //       }
  //       return res;
  //     }),
  //   );
  // }

  getQuizAttendees(quizId: any, productType: any, start: any, length: any, searchText: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/qattendee?quizId=${quizId}&productType=${productType}&max=${length}&start=${start}&search=${searchText}`;
    return this.http.get(apiUrl);
  }

  removeQuizAttendee(contactId: any, quizId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/edtest/qattendee/${contactId}`;
    return this.http.delete(apiUrl);
  }

  getAlltests(operations = {}): Observable<any> {
    const params = { start: 0, max: 50, search: '', isShowArchived: false, sort: 'updatedAt', order: 'asc', ...operations };
    if (!params.isShowArchived) {
      delete params.isShowArchived;
    }
    return this.http.get(`${environment.apiBaseUrl}/edtest/quiz?`, { params });
  }

  getTestWithoutPagination(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/edtest/quiz/all`);
  }

  getQuizSets(quizId) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/quiz/${quizId}/sets`);
  }

  putTest(test: any, isNotify = true) {
    return this.http.put(`${environment.apiBaseUrl}/edtest/quiz/${test._id}`, test).pipe(
      map((item: any) => {
        this._bunnyUploadService.isLoading = { description: false };
        if (isNotify) {
          this._assessmentSubject.next(item.data);
        }
        return item.data;
      }),
      catchError((error: any) => {
        return of(null);
      }),
    );
  }

  getTest(courseId, chapterId) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/quiz?courseId=${courseId}&chapterId=${chapterId}`);
  }

  getTestCertificate(payload) {
    return this.http.post(`${environment.apiBaseUrl}/edtest/certificate`, payload);
  }

  getPaperResultCertificate(resultId) {
    return this.http.post(`${environment.apiBaseUrl}/edtest/result/${resultId}/certificate`, {});
  }

  getQuestions(qBank) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/qbank/${qBank}/question?start=0&max=999`);
  }

  getQbankChapter(qBank) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/qbank/${qBank}/chapter`);
  }

  getDefaultInstruction(orgId) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/tenant?name=${orgId}&isDefaultInstruction=true`);
  }

  getTenantCourseSettings(orgId) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/tenant/settings/course?name=${orgId}`);
  }

  updateLesson(lesson: any, courseId) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}`, lesson);
  }

  updateSection(section: any) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${section.course}/section/${section.guId}`, section);
  }

  getLessonList(course: any, section: any) {
    return this.http.get(`${this.baseUrl}/rest/itemCategory/${course}/section/${section}/chapter`);
  }

  getNotes(courseId, sectionId, chapterId) {
    return this.http.get(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${sectionId}/chapter/${chapterId}/note`);
  }

  deleteLesson(lesson: any, courseId) {
    return this.http.delete(`${environment.apiBaseUrl}/snode/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}`);
  }

  deleteQuiz(quizId) {
    return this.http.delete(`${environment.apiBaseUrl}/edtest/quiz/${quizId}`);
  }

  deleteSection(section: any) {
    return this.http.delete(`${environment.apiBaseUrl}/snode/icategory/${section.course}/section/${section.guId}`);
  }

  getQuestionBanks(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/edtest/qBank?max=50&offset=0`);
  }

  deleteAsset(assestId, fileName) {
    return this.http.delete(`${environment.apiBaseUrl}/edmedia/asset/${assestId}`);
  }

  getUnlayerTemplate(guId: string, name: string) {
    return this.http.get(`${environment.apiBaseUrl}/stemplate/public/course/${guId}`, { withCredentials: false });
  }

  getContactByEmail(email: string) {
    return this.http.get(`${environment.apiBaseUrl}/rest/contact?email=${email}`);
  }

  getCoursesByContactId(start: any, max: any, searchText: any) {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory?start=${start}&max=${max}`;
    if (searchText) {
      apiUrl += `&search=${searchText}`;
    }

    return this.http.get(apiUrl);
  }

  getLesson(lesson: any, courseId) {
    return this.http.get(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}`);
  }

  postNote(lesson: any, courseId, payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}/note`, payload);
  }

  putNote(lesson: any, courseId, payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}/note/${payload.guId}`, payload);
  }

  deleteNote(lesson: any, courseId, noteId: any) {
    return this.http.delete(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${lesson.section}/chapter/${lesson.guId}/note/${noteId}`);
  }

  submitQuiz(quizData: any, quizId) {
    var a = {
      contact: {
        name: 'Ramesh Ahah',
        email: 'iamrameshshah@gmail.com',
        phone: '+1-4088989888',
      },
      answers: [
        { question: { guId: 'LWMdj5NArb' }, answer: [{ guId: 'xnXdgD3GKv' }] },
        { question: { guId: 'ngB0NO9A5e' }, answer: [{ guId: 'wyLA63vdoM' }] },
        { question: { guId: 'wyLA6oVdoM' }, answer: [{ guId: 'Y5bAyXp0LO' }] },
      ],
    };
    return this.http.post(`${environment.apiBaseUrl}/rest/quizResult/${quizId}`, quizData);
  }

  updateLessonSequence(payload: any, courseId: any) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/section/${payload.section}/chapter/sequence`, payload);
  }

  updateSessionSequence(payload: any, courseId) {
    return this.http.put(`${environment.apiBaseUrl}/snode/icategory/section/${courseId}/sequence`, payload);
  }

  putPaper(payload: any) {
    let apiUrl = `${this.baseUrl}/edtest/paper/${payload._id}`;
    return this.http.put(apiUrl, payload);
  }

  getPaper(id: any) {
    let apiUrl = `${this.baseUrl}/edtest/paper/${id}`;
    return this.http.get(apiUrl);
  }

  getCourseBatch(course) {
    return this.http.get(`${environment.apiBaseUrl}/rest/itemCategory/${course}`);
  }

  updateCourseBatch(courseId, payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}`, payload);
  }

  getBatches(batchId = null) {
    if (batchId) {
      batchId = '/' + batchId;
    } else {
      batchId = '';
    }
    return this.http.get(`${environment.apiBaseUrl}/rest/courseBatch${batchId}`);
  }

  updateBatch(batchId, payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/courseBatch/${batchId}`, payload);
  }

  createBatch(payload) {
    return this.http.post(`${environment.apiBaseUrl}/rest/courseBatch`, payload);
  }

  getChapterBatch(chapterId) {
    return this.http.get(`${environment.apiBaseUrl}/rest/courseChapter/${chapterId}/batch`);
  }

  publishChapterBatch(payload) {
    return this.http.put(`${environment.apiBaseUrl}/edtest/batch/update`, payload);
  }

  updateAttendeeBatch(courseId, attendeeId, payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/itemCategory/${courseId}/attendee/${attendeeId}`, payload);
  }

  getActivity(courseId, lessonId, isNotify = true) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/activity?courseId=${courseId}&chapterId=${lessonId}`).pipe(
      map((res: any) => {
        if (isNotify) {
          this._activitySubject.next(res.data);
        }
        return res.data;
      }),
    );
  }

  submitActivity(payload, isNotify = true) {
    payload._id = null;
    return this.http.post(`${environment.apiBaseUrl}/edtest/activity`, payload).pipe(
      map((res: any) => {
        this._bunnyUploadService.isLoading = { description: false };
        if (isNotify) {
          this._activitySubject.next(res);
        }
        return res;
      }),
    );
  }

  putActivity(payload, isNotify = true) {
    return this.http.put(`${environment.apiBaseUrl}/edtest/activity/${payload._id}`, payload).pipe(
      map((res: any) => {
        this._bunnyUploadService.isLoading = { description: false };
        if (isNotify) {
          this._activitySubject.next(res.data);
        }
        return res.data;
      }),
    );
  }

  getActivityAssestData(guId: string, productId: any) {
    return this.http.get(`${environment.apiBaseUrl}/edmedia/asset/?ownerId=${guId}&productType=course&productId=${productId}&isHomework=true`);
  }

  public reviewTestPaper(id, payload) {
    return this.http.put(`${environment.apiBaseUrl}/edtest/result/answer/${id}`, payload);
  }

  getDownloadPdf(guId) {
    let url = `${environment.apiBaseUrl}/cform/pdf/spreport/${guId}`;
    return this.http.get(url);
  }

  addSetCategory(payload) {
    return this.http.post(`${environment.apiBaseUrl}/edtest/setcategory`, payload);
  }

  updateSetCategory(payload) {
    return this.http.put(`${environment.apiBaseUrl}/edtest/setcategory/${payload._id}`, payload);
  }

  getSetCategoryList() {
    return this.http.get(`${environment.apiBaseUrl}/edtest/setcategory`);
  }

  getSetCategory(id) {
    return this.http.get(`${environment.apiBaseUrl}/edtest/setcategory/${id}`);
  }

  deleteSetCategory(id) {
    return this.http.delete(`${environment.apiBaseUrl}/edtest/setcategory/${id}`);
  }
}
