<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p>Please Wait.</p>
</ngx-spinner>

<div *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlexAlign="start center">
      <mat-icon class="logo-icon s-32 mr-8"> desktop_windows </mat-icon>
      <span class="logo-text h1"> Module Settings </span>
    </div>
    <div>
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fxLayout="column" fxLayoutGap="1rem">
    <form fxLayout="column" fxLayoutGap="1rem" [formGroup]="toggleForm" fxFlexFill>
      <div class="section-container">
        <h2>Manage Modules</h2>
        <div class="toggle-container" fxLayout="column" fxLayoutGap="1rem">
          <mat-slide-toggle formControlName="isShowEventsMenu" labelPosition="after">
            Events
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowScheduleMenu" labelPosition="after">
            Classes
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowDonationMenu" labelPosition="after">
            Donations
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowMerchandiseMenu" labelPosition="after">
            Merchandise
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowAssessment" labelPosition="after">
            Assessment
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowFormsMenu" labelPosition="after">
            Forms
          </mat-slide-toggle>
        </div>
      </div>
      <div class="section-container">
        <h2>Engage Modules</h2>
        <div class="toggle-container" fxLayout="column" fxLayoutGap="1rem">
          <mat-slide-toggle formControlName="isShowCampaignsMenu" labelPosition="after">
            Campaigns
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="isShowStoreMenu" labelPosition="after">
            Store
          </mat-slide-toggle>
        </div>
      </div>
    </form>
    <form fxLayout="column" fxLayoutGap="1rem" [formGroup]="otherForm" (submit)="updateOtherSettings()">
      <div class="section-container" fxLayout="column">
        <h2>Other Settings</h2>
        <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
          <span style="margin-right: 20px; margin-bottom: 20px" class="courseSubTitleClass">
            Do you have members from other countries?
          </span>
            <mat-slide-toggle
            [(ngModel)]="isMultiCountrySupport"
            formControlName="isMultiCountrySupport">
            <span style="font-size: 15px" *ngIf="isMultiCountrySupport">Yes</span>
            <span style="font-size: 15px" *ngIf="!isMultiCountrySupport">No</span>
          </mat-slide-toggle>
        </div>
        <div class="toggle-container" fxLayout="column">
          <mat-slide-toggle formControlName="isWaiverFormToBeSigned" labelPosition="after">
            Waiver Form
          </mat-slide-toggle>
          <span class="courseSubTitleClass mb-16" fxFlex="100">
            If checked, User has to sign Waiver form before he is added to system
          </span>
          <ng-container *ngIf="otherForm.get('isWaiverFormToBeSigned').value">
            <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex fxFlex.gt-xs="50" matInput>
                  <mat-label>Waiver Form Link</mat-label>
                  <input matInput formControlName="waiverFormLink"
                    placeholder="e.g. http://www.yoursite.com/waiver-form-link" />
                  <mat-error>Waiver Form Link is required!</mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <mat-slide-toggle formControlName="isSupportGrn" labelPosition="after">
            Support User ID
          </mat-slide-toggle>
          <span class="courseSubTitleClass mb-16" fxFlex="100">
            If enabled User ID / GRN will be enableds
          </span>
          <div class="settingTitle">Calendar View</div>
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex fxFlex.gt-xs="50">
              <mat-label class="courseTitleClass">Calendar Website view</mat-label>
              <mat-select name="isShowCalenderView" formControlName="isShowCalenderView">
                <mat-option class="courseTitleClass" [value]="false"> Monthly View </mat-option>
                <mat-option class="courseTitleClass" [value]="true"> Weekly View </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="settingTitle">Button Labels</div>
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex fxFlex.gt-xs="50" matInput>
              <mat-label>Register Button Label</mat-label>
              <input matInput formControlName="registerButtonLabel" placeholder="Upcoming Events" />
              <mat-error>Register Button Label is required!</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>
          <button type="submit" mat-flat-button color="accent">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>