import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CountryCodes } from '../../enums/country-codes';
import { TenantPing } from 'app/core/models/tenantPing';
import { SubSink } from 'subsink';
import { AppLoadService } from 'app/core/services/appload.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  _tenantConfig : TenantPing;
  isMultiCountrySupport: boolean = false;
  byDefaultCountry: string = '';        
  defaultDialCode: string = '';
  @Output() getPhoneNumber = new EventEmitter<string>();
  @Input() phone: string = "";
  @Input() heading: boolean;
  @Input() disabled: boolean;
  @Input() isReadOnly: boolean = false;
  @Input() userform:any;
  @Input() countryCode: string;
  countryCodeList: any[] = [
    { name: "United States", dial_code: "+1", code: "USA", mask: "000-000-0000" },
    { name: "India", dial_code: "+91", code: "IND", mask: "00000-00000" },
    { name: "United Kingdom", dial_code: "+44", code: "GBR", mask: "0000-000-000" },
    { name: "Australia", dial_code: "+61", code: "AUS", mask: "0000-000-000" },
    { name: "Singapore", dial_code: "+65", code: "SGP", mask: "0000-0000" },
    // { name: "France (for EUR)", dial_code: "+33", code: "FR", mask: "0-00-00-00-00" },
    // { name: "UAE", dial_code: "+971", code: "AE", mask: "00-000-0000" },
    // { name: "Japan", dial_code: "+81", code: "JP", mask: "00-0000-0000" },
    // { name: "China", dial_code: "+86", code: "CN", mask: "000-0000-0000" },
    // { name: "Indonesia", dial_code: "+62", code: "ID", mask: "0000-000-000" },
    // { name: "Malaysia", dial_code: "+60", code: "MY", mask: "000-000-0000" },
    // { name: "Philippines", dial_code: "+63", code: "PH", mask: "0000-000-0000" }
  ];
  //CountryCodes;
  //{name: "Canada", dial_code: "+1", code: "CA"}
  countryCodeList$: Observable<any[]>;
  public phoneForm: FormGroup;
  // extensionNumber = new FormControl();
  // phoneNumber = new FormControl();
  mask = "000-000-0000";
  constructor(
    private _formBuilder :  FormBuilder,
    private _appLoadService : AppLoadService
  ) {
    this.phoneForm = this._formBuilder.group({
      extensionNumber : [""],
      phoneNumber: [""],
    })
    this.countryCodeList$ = this.phoneForm.get('extensionNumber').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }
    
    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.countryCodeList.filter(country => country.dial_code.concat(country.name).toLowerCase().includes(filterValue));
    }
    
    ngOnInit() {
 
    this.bindPhoneNumber();
    this.phoneForm.get("phoneNumber").valueChanges.subscribe(res => {
      this.emitPhoneNumber();
    });
    this.phoneForm.get('extensionNumber').valueChanges.subscribe(res => {
      this.emitPhoneNumber();
    });
    this._appLoadService.onTenantConfigChanged.subscribe((tenantConfig: any) => {
      this._tenantConfig = tenantConfig;
      this.isMultiCountrySupport = this._tenantConfig.isMultiCountrySupport;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.disabled?.currentValue !== changes?.disabled?.previousValue) {
      if(changes?.disabled?.currentValue === true) {
        this.phoneForm.get("phoneNumber").disable();
        this.phoneForm.get('extensionNumber').disable();
      } else {
        this.phoneForm.get("phoneNumber").enable();
        this.phoneForm.get('extensionNumber').enable();
      }
    }
    if(changes?.phone?.currentValue !== changes?.phone?.previousValue) {
      this.bindPhoneNumber();
    }
    if(!this.isMultiCountrySupport){
      this.mask = this.getMaskByDialCode(this.countryCode)
    } else {
      this.mask = this.getMaskByDialCode(this.phoneForm.get('extensionNumber').value);
    }
  }

  bindPhoneNumber() {
    if (this.phone?.trim() != '') {
      let numberInArray = this.phone?.split('-');
      let code= '', phoneNum = this.phone;
      if (numberInArray?.length === 2) {
       code = numberInArray[0];
       phoneNum = numberInArray[1];
      }
      this.phoneForm.get('extensionNumber').setValue(code);
      this.phoneForm.get("phoneNumber").setValue(phoneNum == undefined ? '' : phoneNum);
    }
  }

  getMaskByDialCode(dialCode: string): string {
    const country = this.countryCodeList.find(item => item.dial_code === dialCode);
    return country ? country.mask : '';
  }
  
  emitPhoneNumber(): void {
    const extension = this.phoneForm.get('extensionNumber')?.value || '';
    const phoneNumber = this.phoneForm.get('phoneNumber')?.value || '';
    if (!phoneNumber) {
      this.getPhoneNumber.emit('');
    } else if (this.isMultiCountrySupport) {
      this.getPhoneNumber.emit(`${extension}-${phoneNumber}`);
    } else {
      this.getPhoneNumber.emit(`${phoneNumber}`);
    }
  }
}
