<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p class="color-white">Please Wait.</p>
</ngx-spinner>

<div *ngIf="!isLoading" class="full-div" fxLayout="column" fxLayoutAlign="center center">
  <div class="resize-div">
    <div class="w-100-p card">
      <div class="w-100-p main-box" *ngIf="step === 2" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="30" class="h-100-p inner-left-box" fxLayout="column" fxLayoutAlign="center start">
          <div class="element-center max-width-300">
            <div class="p-20 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <img src="https://passets.wajooba.com/img/Onboarding_Placeholder.png" class="w-100-p" />
            </div>
            <div class="mt-12 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <h1 class="m-0 font-size-20 font-weight-800 blue-color">
                {{ user.name }}
              </h1>
            </div>
            <div class="w-100-p my-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.email }}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100-p main-box">
        <div fxFlex="30" class="h-100-p inner-left-box" fxLayout="column" fxLayoutAlign="center center">
          <div class="element-center max-width-300">
            <div class="p-20 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <img src="https://passets.wajooba.com/img/Onboarding_Placeholder.png" class="w-100-p" />
            </div>
            <div class="mt-12 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <h1 class="m-0 font-size-20">Let's get Started</h1>
            </div>
            <div class="w-100-p mt-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.fullName }}</h4>
            </div>
            <div class="w-100-p my-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.email }}</h4>
            </div>
          </div>
        </div>
        <div fxFlex="70" class="h-100-p inner-right-box border-left" fxLayout="column" fxLayoutAlign="start start">
          <mat-horizontal-stepper #stepper class="custom-stepper" linear>
            <mat-step [stepControl]="firstFormGroup">
              <div fxLayout="column" fxLayoutAlign="center center">
                <form [formGroup]="firstFormGroup" fxLayout="column" fxFlexFill>
                  <h2 style="padding: 1rem" fxLayoutAlign="center">Let's configure your account
                  </h2>
                  <mat-form-field fxFlex="1 1 20%" appearance="outline">
                    <mat-label>Which of these most sounds like you?</mat-label>
                    <mat-select formControlName="businessType" required>
                      <mat-option value="wellness-trainer">Dance, Yoga, and Wellness Studio</mat-option>
                      <mat-option value="coach">Training Class (Academic Institute)</mat-option>
                      <mat-option value="non-profit">Professional Trainer, Coache, and iPro</mat-option>
                      <mat-option value="non-profit">Creator, Influencer, and Content Agency</mat-option>
                      <mat-option value="non-profit">Event Organizer</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field fxFlex="1 1 20%" appearance="outline">
                    <mat-label>Organization Size</mat-label>
                    <mat-select formControlName="businessSize" required>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="5">5</mat-option>
                      <mat-option value="10">10</mat-option>
                      <mat-option value="Other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field fxFlex="1 1 20%" appearance="outline">
                    <mat-label>How did you hear about Wajooba?</mat-label>
                    <input matInput formControlName="businessReferer" />
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button mat-raised-button color="accent" (click)="next()" *ngIf="!isLoading">Next</button>
                    <span *ngIf="isLoading">
                      <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                  </div>
                </form>
              </div>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
              <div fxLayout="column" fxLayoutAlign="start center">
                <form [formGroup]="secondFormGroup" fxLayout="column" fxFlexFill>
                  <h2 fxLayoutAlign="center">Business Information</h2>
                  <mat-form-field fxFlex="1 1 100%" appearance="outline">
                    <mat-label>Business Name</mat-label>
                    <input matInput formControlName="name" placeholder="Enter your business name" required />
                  </mat-form-field>

                  <app-file-upload [orgId]="orgId" [photos]="bannerImage" (getFileObject)="bannerImage$.next($event)"
                    [title]="''" [buttonName]="'Upload Logo'" class="text-color" [description]="'Business Logo'"
                    [prePhoto]="tenant?.org?.logo ? buildImage(tenant?.org?.logo) : ''">
                  </app-file-upload>

                  <app-phone-number fxFlex="100" style="width: 100%" [countryCode]="countryCode"
                    (getPhoneNumber)="getPhoneNumber($event)" [phone]="phone" [heading]="true"
                    [isReadOnly]="isReadOnly">
                  </app-phone-number>

                  <div *ngIf="secondFormGroup.get('phone').invalid && secondFormGroup.get('phone').touched">
                    <small class="text-danger" *ngIf="secondFormGroup.get('phone').hasError('required')">Phone number is
                      required.</small>
                  </div>

                  <mat-form-field fxFlex="1 1 20%" appearance="outline">
                    <mat-label>Select Country</mat-label>
                    <mat-select formControlName="country" (selectionChange)="onCountryChange($event.value)" required>
                      <mat-option *ngFor="let country of countryList" [value]="country.value">
                        {{ country.text }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field fxFlex="1 1 20%" appearance="outline" fxFlex="50">
                    <mat-label>Time Zone</mat-label>
                    <mat-select formControlName="timezone" required>
                      <mat-option *ngFor="let timeZone of filteredTimeZoneList"
                        [value]="timeZone?.value || filteredTimeZoneList[0]">
                        {{ timeZone.text }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field fxFlex="1 1 20%" appearance="outline" fxFlex="50">
                    <mat-label>Website</mat-label>
                    <input matInput formControlName="companyUrl" placeholder="e.g., www.yourwebsitename.com" />
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                    <button mat-raised-button color="accent" matStepperPrevious>Previous</button>
                    <button mat-raised-button color="accent" (click)="next()" [disabled]="!secondFormGroup.valid"
                      *ngIf="!isLoading">Next</button>
                    <span *ngIf="isLoading">
                      <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                  </div>
                </form>
              </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup">
              <h2 fxLayoutAlign="center">Select The Products You Offer</h2>
              <form [formGroup]="thirdFormGroup" fxFlex fxLayout="column">
                <div>
                  <mat-slide-toggle [disabled]="true" class="mb-0" formControlName="isShowCoursesMenu"
                    labelPosition="after"><span class="toggle-text">Courses </span></mat-slide-toggle>
                  <h4 class="mb-0 toggle-description">Create Courses,add content like videos, tests, etc. track member
                    progress</h4>
                </div>
                <div>
                  <mat-slide-toggle class="mb-0" formControlName="isShowScheduleMenu" labelPosition="after"><span
                      class="toggle-text">Classes </span></mat-slide-toggle>
                  <h4 class="mb-0 toggle-description">Create and public class calender wit few clicks</h4>
                </div>
                <div>
                  <mat-slide-toggle class="mb-0" formControlName="isShowEventsMenu" labelPosition="after"><span
                      class="toggle-text">Events </span></mat-slide-toggle>
                  <h4 class="mb-0 toggle-description">Multiple Ways to host Events</h4>
                </div>
                <div>
                  <mat-slide-toggle class="mb-0" formControlName="isShowAssessment" labelPosition="after"><span
                      class="toggle-text">Assessments </span></mat-slide-toggle>
                  <h4 class="mb-0 toggle-description">Create Question Banks, Host Practice and Final Assessments. Give
                    Certificates</h4>
                </div>
                <div>
                  <mat-slide-toggle class="mb-0" formControlName="isShowDonationMenu" labelPosition="after"><span
                      class="toggle-text">Donations </span></mat-slide-toggle>
                  <h4 class="mb-0 toggle-description">Accept Donations, One Time and Recurring</h4>
                </div>
                <div>
                  <mat-slide-toggle class="mb-0" formControlName="isShowMerchandiseMenu" labelPosition="after"><span
                      class="toggle-text">Merchandise </span></mat-slide-toggle>
                  <h4 class="toggle-description mb-16">Sell Merchandise from store Or Website</h4>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                  <button mat-raised-button color="accent" matStepperPrevious>Previous</button>
                  <button mat-raised-button color="accent" type="submit" (click)="next()">Submit</button>
                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </div>
  </div>
</div>