export class AppConstants {
  public static ALLOWED_COOKIE_SITES = []; //""onwajooba.com","wajooba.me",armcrm.me",'me.com',"wajooba.xyz"

  //PAGE CONSTANTS
  public static PAGE_DEFAULT_TITLE = "Wajooba";
  public static PAGE_DEFAULT_DESCRIPTION = "Wajooba - platform to monetize your knowledge, experience and expertise";
  public static PAGE_DESCRIPTION = 'description';
  public static PAGE_KEYWORDS = 'keywords';
  public static PAGE_ROBOTS = 'robots';
  public static PAGE_OG_TITLE = 'og:title';
  public static PAGE_OG_DESCRIPTION = 'og:description';
  public static PAGE_OG_IMAGE = 'og:image';
  public static PAGE_TWITTER_TITLE = 'twitter:title';
  public static PAGE_TWITTER_DESCRIPTION = 'twitter:description';
  public static PAGE_TWITTER_IMAGE = 'twitter:image';

  //pageNames
  public static PAGE_ABOUT = 'about';
  public static PAGE_CONTACT = 'contact';
  public static PAGE_HOME = 'home';
  public static PAGE_CALENDAR = 'calendar';
  public static PAGE_EVENTS = 'events';
  public static PAGE_COURSES = 'courses';
  public static PAGE_FORMS = 'forms';
  public static PAGE_MERCHANDISE = 'merchandise';
  

  public static standardPages = [AppConstants.PAGE_HOME, AppConstants.PAGE_CALENDAR, AppConstants.PAGE_EVENTS, AppConstants.PAGE_COURSES, AppConstants.PAGE_FORMS, AppConstants.PAGE_MERCHANDISE];

  public static HOME_PAGE = 'home';
  public static EVENTS_PAGE = 'events';
  public static WEBSITE_REFRESHED = 'website_refreshed';
  //menu types
  public static MENU_TYPE_ITEM = 'item';
  public static MENU_TYPE_GROUP = 'group';
  public static MENU_TYPE_COLLAPSABLE = 'collapsable';
  public static MENU_MERCHANDISE = 'products';
  public static MENU_CAMPAIGNS = 'campaign';
  public static MENU_EVENTS = 'rules';
  
  // Navigation keys
  public static PUBLIC_NAVIGATION = 'main';
  public static ADMIN_NAVIGATION = 'admin';
  public static STUDENT_NAVIGATION = 'student';
  public static ADMIN_LAYOUT = 'vertical-layout-1';
  public static STUDENT_LAYOUT = 'vertical-layout-1';
  public static PUBLIC_LAYOUT = 'horizontal-layout-1';
  public static PUBLIC_FEATURED_EVENTS = 'public/featuredEvents';

  // public static AUTHMODE = "LOCALSTORAGE";
  //   public static AUTHMODE = "COOKIE";
  public static ADMIN_TOKEN = 'ADMIN';
  public static AUTHTOKEN_KEY = 'auth';
  public static ZOOMTOKEN_KEY = 'zoom';
  public static SOCIAL_LOGIN = 'access_token';
  public static FACEBOOK_LOGIN = 'facebook';
  public static AUTH0_LOGIN = 'Auth0';
  public static LOGIN_TRACE = 'logintrace';
  public static USER_ROLE = 'userrole';
  public static FRANCHISE_LOGIN = 'franchise';
  public static MASTER_FRANCHISE = 'master';
  public static MASTER_TENANT = 'master';
  public static FRANCHISE_LOGIN_SWITCH = 'franchise_mode';
  public static FRANCHISE_LOGIN_SWITCH_USER = 'masterAdminId';

  public static TENANT_PANKAJTEST = 'pankajtest5';
  public static TENANT_MINDSYNCKIDS = 'mindsynckids';
  public static TENANT_MARKSAMPLETEST = 'marksampletest';

  public static FACEBOOK_AUTH_TYPE = 'fb';
  public static SOCIAL_LOGIN_REDIRECT_URL = 'https://wajooba.me/appredirect.htm';
  public static SOCIAL_LOGIN_CLIENT_ID = '1048532435589501';

  // public static AUTHMODE_LOCAL_STORAGE = "LOCALSTORAGE";
  public static WHITELIST_APIS = [];
  public static ROLE_STUDENT = 'ROLE_STUDENT';
  public static ROLE_STAFF = 'ROLE_STAFF';
  public static ROLE_ADMIN = 'ROLE_ADMIN';
  public static ROLE_FRONTDESK = 'ROLE_FRONTDESK';

  public static MONTH_SEL = 'month';
  public static WEEK_SEL = 'week';

  // routes
  // PUBLIC ROUTES
  static LOGIN_ROUTE = 'login';
  static SL_LOGIN_ROUTE = '/' + AppConstants.LOGIN_ROUTE;

  static REGISTER_ROUTE = 'register';
  static SL_REGISTER_ROUTE = '/' + 'register';

  static FORGOT_PASSWORD_ROUTE = 'forgot-password';
  static SL_FORGOT_PASSWORD_ROUTE = '/' + this.FORGOT_PASSWORD_ROUTE;

  public static CALENDAR_ROUTE = 'calendar';
  public static SL_CALENDAR_ROUTE = '/' + AppConstants.CALENDAR_ROUTE;

  public static ASSESMENT_ROUTE = 'assesment';
  public static SL_ASSESMENT_ROUTE = '/' + AppConstants.ASSESMENT_ROUTE;

  public static COURSES_ROUTE = 'courses';
  public static SL_COURSES_ROUTE = '/' + AppConstants.COURSES_ROUTE;

  public static PREVIEW_ROUTE = 'preview';
  public static SL_PREVIEW_ROUTE = '/' + AppConstants.PREVIEW_ROUTE;

  public static CONTENT_ROUTE = 'content';
  public static SL_CONTENT_ROUTE = '/' + AppConstants.CONTENT_ROUTE;
  
  public static MERCHANDISE_ROUTE = 'merchandise';
  public static SL_MERCHANDISE_ROUTE = '/' + AppConstants.MERCHANDISE_ROUTE;

  public static INVOICE_ROUTE = 'invoice';
  public static SL_INVOICE_ROUTE = '/' + AppConstants.INVOICE_ROUTE;

  public static INVOICES_ROUTE = 'invoices';
  public static SL_INVOICES_ROUTE = '/' + AppConstants.INVOICES_ROUTE;

  public static DONATIONS_ROUTE = 'donations';
  public static SL_DONATIONS_ROUTE = '/' + AppConstants.DONATIONS_ROUTE;

  public static PDONATIONS_ROUTE = 'pdonations';
  public static SL_PDONATIONS_ROUTE = '/' + AppConstants.PDONATIONS_ROUTE;

  public static DONATE_ROUTE = 'donate';
  public static SL_DONATE_ROUTE = '/' + AppConstants.DONATE_ROUTE;

  public static STANDARD_PAGE_FORMS_NAME = 'forms';
  public static PUBLIC_REGISTRATION_FORM_ROUTE = 'pforms';
  public static SL_PUBLIC_REGISTRATION_FORM = '/' + AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE;

  public static PRODUCTS_ROUTE = 'products';
  public static SL_PRODUCTS_ROUTE = '/' + AppConstants.PRODUCTS_ROUTE;

  public static FACILITY_ROUTE = 'facility';
  public static SL_FACILITY_ROUTE = '/' + AppConstants.FACILITY_ROUTE;

  public static FORMS_ROUTE = 'forms';
  public static SL_FORMS_ROUTE = '/' + AppConstants.FORMS_ROUTE;

  public static NEW_PRODUCTS_ROUTE = 'new';
  public static SL_NEW_PRODUCTS_ROUTE = AppConstants.SL_PRODUCTS_ROUTE + '/' + AppConstants.NEW_PRODUCTS_ROUTE;

  public static STORE_ROUTE = 'store';
  public static SL_STORE_ROUTE = '/' + AppConstants.STORE_ROUTE;

  public static NEW_STORE_ROUTE = 'new';
  public static SL_NEW_STORE_ROUTE = AppConstants.SL_STORE_ROUTE + '/' + AppConstants.NEW_STORE_ROUTE;

  public static REPORTS_ROUTE = 'reports';
  public static SL_REPORTS_ROUTE = '/' + AppConstants.REPORTS_ROUTE;

  public static OFFERS_ROUTE = 'offers';
  public static SL_OFFERS_ROUTE = '/' + AppConstants.OFFERS_ROUTE;
  public static SL_OFFERSDETAIL_ROUTE = '/' + AppConstants.OFFERS_ROUTE + '/details';

  public static EVENTS_ROUTE = 'events';
  public static SL_EVENTS_ROUTE = '/' + AppConstants.EVENTS_ROUTE;

  public static CHECKOUT_ROUTE = 'checkout';
  public static SL_CHECKOUT_ROUTE = '/' + AppConstants.CHECKOUT_ROUTE;

  public static CHECKOUT_REGISTER_FORM = 'free';
  public static SL_CHECKOUT_REGISTER_FORM = '/' + AppConstants.CHECKOUT_REGISTER_FORM;

  public static PREVIEW_REGISTER_FORM = 'introductory';
  public static SL_PREVIEW_REGISTER_FORM = '/' + AppConstants.PREVIEW_REGISTER_FORM;

  public static SL_COURSEDETAIL_ROUTE = '/' + AppConstants.COURSES_ROUTE + '/details';
  public static SL_EVENTDETAIL_ROUTE = '/' + AppConstants.EVENTS_ROUTE + '/details';

  public static FILES_ROUTE = 'files';
  public static SL_FILES_ROUTE = '/' + AppConstants.FILES_ROUTE;

  // Cart Checkout
  public static CART_CHECKOUT_ROUTE = 'cart-checkout';
  public static SL_CART_CHECKOUT_ROUTE = '/store/' + AppConstants.CART_CHECKOUT_ROUTE;

  // stuent routes
  public static MYCOURSES_ROUTE = 'mycourses';
  public static SL_MYCOURSES_ROUTE = '/' + AppConstants.MYCOURSES_ROUTE;

  public static STUDENTASSESSMENTS_ROUTE = 'student-assessments';
  public static SL_STUDENTASSESSMENTS_ROUTE = '/' + AppConstants.STUDENTASSESSMENTS_ROUTE;

  public static MYREGISTRATION_ROUTE = 'myform';
  public static SL_MYREGISTRATION_ROUTE = '/' + AppConstants.MYREGISTRATION_ROUTE;

  public static MYASSESMENT_ROUTE = 'myassesment';
  public static SL_MYASSESMENT_ROUTE = '/' + AppConstants.MYASSESMENT_ROUTE;

  public static MYFILES_ROUTE = 'myfiles';
  public static SL_MYFILES_ROUTE = '/' + AppConstants.MYFILES_ROUTE;

  public static ONBOARDING_ROUTE = 'onboarding';
  public static SL_ONBOARDING_ROUTE = '/' + AppConstants.ONBOARDING_ROUTE;

  public static MYDONATION_ROUTE = 'mydonation';
  public static SL_MY_DONATION_ROUTE = '/' + AppConstants.MYDONATION_ROUTE;

  // admin routes
  public static DASHBOARD_ROUTE = 'dashboard';

  public static APP_SETTINGS_ROUTE = 'settings';
  public static SL_APP_SETTINGS_ROUTE = '/' + AppConstants.APP_SETTINGS_ROUTE;

  public static USER_SETTINGS_ROUTE = 'users';
  public static SL_USER_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.USER_SETTINGS_ROUTE;
  public static SL_USER_DETAILS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.USER_SETTINGS_ROUTE + '/';

  public static ACCOUNTS_SETTINGS_ROUTE = 'account';
  public static SL_ACCOUNTS_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.ACCOUNTS_SETTINGS_ROUTE;

  public static DISPLAY_SETTINGS_ROUTE = 'display';
  public static SL_DISPLAY_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.DISPLAY_SETTINGS_ROUTE;

  public static STUDENTAPP_SETTINGS_ROUTE = 'studentapp';
  public static SL_STUDENTAPP_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.STUDENTAPP_SETTINGS_ROUTE;

  public static ROLES_SETTINGS_ROUTE = 'roles';
  public static SL_ROLES_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.ROLES_SETTINGS_ROUTE;

  public static CUSTOMFIELD_SETTINGS_ROUTE = 'customfield';
  public static SL_CUSTOMFIELD_SETTINGS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.CUSTOMFIELD_SETTINGS_ROUTE;

  public static CUSTOMFORMS_ROUTE = 'customforms';
  public static SL_CUSTOMFORMS_ROUTE ='/' + AppConstants.CUSTOMFORMS_ROUTE;

  public static REGISTRATIONFORMS_ROUTE = 'regforms';
  public static SL_REGISTRATIONFORM_ROUTE = '/' + AppConstants.REGISTRATIONFORMS_ROUTE;
  public static SL_REGISTRATIONFORMS_ROUTE = AppConstants.SL_APP_SETTINGS_ROUTE + '/' + AppConstants.REGISTRATIONFORMS_ROUTE;

  public static TESTS_ROUTE = 'tests';
  public static SL_TESTS_ROUTE = '/' + AppConstants.TESTS_ROUTE;

  public static LOGOUT_ROUTE = 'logout';
  public static SL_LOGOUT_ROUTE = '/' + AppConstants.LOGOUT_ROUTE;

  public static PROFILE_ROUTE = 'profile';
  public static SL_PROFILE_ROUTE = '/' + AppConstants.PROFILE_ROUTE;

  // dashboard
  public static MONTH = 'month';
  public static SL_DASHBOARD_ROUTE = '/' + AppConstants.DASHBOARD_ROUTE;
  public static SERVICES_ROUTE = 'services';
  public static PRODUCT_BUNDLE_ROUTE = 'product-bundle';
  public static SL_PRODUCT_BUNDLE_ROUTE = '/' + AppConstants.PRODUCT_BUNDLE_ROUTE;
  public static SCHEDULE_ROUTE = 'schedule';
  public static CLASSES_ROUTE = 'classes';
  public static SL_CLASSES_ROUTE = '/' + AppConstants.CLASSES_ROUTE;

  public static SIGNUP_ROUTE = 'signup';
  public static SL_SIGNUP_ROUTE = '/' + AppConstants.SIGNUP_ROUTE;

  public static STUDENT_INVOICE_ROUTE = 'meminvoice';
  public static SL_STUDENT_INVOICE_ROUTE = '/' + AppConstants.STUDENT_INVOICE_ROUTE;

  public static QUESTIONBANK_ROUTE = 'qbank';
  public static ADD_QUESTIONBANK_ROUTE = 'qbank/add';
  public static SIGNIN_ROUTE = 'signin';
  public static SL_SIGNIN_ROUTE = '/' + AppConstants.SIGNIN_ROUTE;

  public static EMAILCAMPAIGNS_ROUTE = 'campaign';
  public static RULES_ROUTE = 'rules';
  public static CLASS_ROUTE = 'class';
  public static CLASS_RULES_ROUTE = 'classlist';
  public static REVIEWS_ROUTE = 'reviews';

  public static SL_SERVICES_ROUTE = '/' + AppConstants.SERVICES_ROUTE;
  public static SL_QUESTIONBANK_ROUTE = '/' + AppConstants.QUESTIONBANK_ROUTE;
  public static SL_ADD_QUESTIONBANK_ROUTE = '/' + AppConstants.ADD_QUESTIONBANK_ROUTE;
  public static CONTACTS_ROUTE = 'contacts';
  public static CLAN_MEETING_DOMAIN = 'ap01';
  public static OLD_CLAN_MEETING_DOMAIN = 'wajooba';
  // contacts
  public static CONTACTS_TYPE = 'contacts';

  public static SL_EMAILCAMPAIGNS_ROUTE = '/' + AppConstants.EMAILCAMPAIGNS_ROUTE;
  public static SIDEBAR_CONTACT_TYPE = 'contact';
  public static SL_CONTACTS_ROUTE = '/' + AppConstants.CONTACTS_ROUTE;
  public static SL_SCHEDULE_ROUTE = '/' + AppConstants.SCHEDULE_ROUTE;
  public static SL_RULES_ROUTE = '/' + AppConstants.RULES_ROUTE;
  public static SL_CLASS_ROUTE = '/' + AppConstants.CLASS_ROUTE;
  public static SL_CLASS_RULE_ROUTE = '/' + AppConstants.CLASS_RULES_ROUTE;

  public static MEDIA_GALLERY_ROUTE = 'mediagallery';
  public static SL_MEDIA_GALLERY_ROUTE = `/${this.MEDIA_GALLERY_ROUTE}`;

  public static SALES_STAGE = 'sales';
  public static ACCOUNT_STAGE = 'account';

  public static INT_WEBSITE = 'int_website';
  public static NO_WEBSITE = 'no_website';

  public static INITIAL_EVENT = 'initial';

  //setting constants
  public static TENANT_NO_MENU_FOUND = 'no_menu_found';
  public static TENANT_ERR_HAPPENED = 'err_happened';
  public static TENANT_NO_CONFIG = 'no_config';

  public static WEBSTATE_EXT = 'ext_website';
  public static WEBSTATE_INT = 'int_website';
  public static WEBSTATE_NONE = 'no_website';

  public static MENU_APPLICATION_ID = 'applications';
  public static MENU_SCHEDULE_ID = 'schedule';
  public static MENU_DONATION_ID = 'donation';
  public static MENU_SERVICES_ID = 'services';
  public static MENU_REFORMS_ID = 'regforms';
  public static MENU_EVENTS_ID = 'events';
  public static MENU_FORM_ID = 'form';
  public static MENU_ASSESSMENT_ID = 'tests';

  public static MENU_STORE = 'store';
  public static MENU_TENANTPLAN = 'tenantplan';
  public static MENU_TENANT = 'tenant';
  public static MENU_ASSESSMENT = 'assesment';
  public static MENU_PUBLIC_CALENDAR = 'calendar';
  public static MENU_PUBLIC_COURSES = 'courses';

  public static MENU_POSITION_START = 'start';
  public static MENU_POSITION_END = 'end';

  // other constants
  public static USER_EMAIL = 'email';
  public static REMEMBER_PASS_DATA = 'rememberPassData';
  public static ORG_ID = 'orgId';
  public static USER_PASSWORD = 'password';

  public static PING_API = 'snode/tenant/ping';
  public static LOGIN_API = 'public/user/signin';
  public static REFRESH_ACCESS_TOKEN_API = 'public/auth/refresh';
  public static VALIDATE_ACCESS_TOKEN_API = 'rest/reinitinfo/token/validate';

  public static LOGOUT_API = 'public/user/logout';
  public static FORGOT_PASSWORD_API = 'public/user/forgotPassword';
  public static AUTH_TOKEN_API = 'public/authToken';
  public static AUTH_REFRESH_TOKEN__KEY = 'refreshauth';
  public static AUTH_TOKEN_EXPIRATION_KEY = 'authexpiry';
  public static REGISTRATION_API = 'public/user/registration';
  public static PUBLIC_FITNESSCLASS_API = 'public/publicFitnessClass';
  public static UNLAYER_TEMPLATE_API = 'unlayertemplate/';
  public static PUBLIC_SCHEDULE_MORE_INFO_API = 'public/publicScheduleMoreInfo';
  public static REINIT_INFO_API = 'rest/reInitInfo';
  public static PAYMENTSPRING_API = 'https://api.paymentspring.com';
  public static STEMPLATE_API = 'stemplate/';

  public static PAGE_NOT_FOUND = '404';
  public static HOME = 'home';
  public static PAGE_LOGOUT = 'logout';
  public static SL_NO_LOGOUT = '/' + AppConstants.HOME;
  public static SL_HOME = '/' + AppConstants.HOME;
  public static SL_NO_ROUTE = '';
  // donation
  public static DONATION_ROUTE = 'donation';
  public static SL_DONATION_ROUTE = '/' + AppConstants.DONATION_ROUTE;

  public static ABOUT_ROUTE = 'about';
  public static SL_ABOUT_ROUTE = '/' + AppConstants.ABOUT_ROUTE;

  public static CONTACT_ROUTE = 'contact';
  public static SL_CONTACT_ROUTE = '/' + AppConstants.CONTACT_ROUTE;

  public static OAUTH = 'oauth';
  public static SL_OAUTH = '/' + AppConstants.OAUTH;

  public static PaymentType = {
    FREE: 'FREE',
    PAID: 'PAID',
    EXTERNAL: 'EXTERNAL',
    DONATION: 'DONATION',
    PRODUCT: 'PRODUCT',
    COURSE: 'COURSE',
  };

  public static REGISTER_CATEGORY_GUID = 'registerCategoryGuId';
  public static EVENT_TYPE = 'eventType';
  public static EVENT_PARAM = 'eventParam';
  public static SIDEBAR_COURSE_TYPE = 'course';
  public static SIDEBAR_COURSE_TEST_RESULT_TYPE = 'testresult';
  public static SIDEBAR_PROGRAM_TYPE = 'program';
  public static SIDEBAR_USER_TYPE = 'user';
  public static CLOUDINARY_URL = 'https://res.cloudinary.com/';
  public static IMG_OPTIONS_PROFILE = 'c_scale,h_85,w_85';
  public static IMG_OPTIONS_PROGRAM = 'c_thumb,h_200,w_310';
  public static IMG_OPTIONS_TEMPLATE = 'c_thumb,h_250,w_330';
  public static IMG_SUFFIX_PATH = '/image/upload/';

  // User Settings
  public static USER_SETTINGS = 'settings';
  public static WEBSITE = 'website';
  public static SL_WEBSITE = '/' + AppConstants.WEBSITE;

  //  Integrations
  public static INTEGRATIONS = 'settings/integrations';
  public static SL_INTEGRATIONS_ROUTE = '/' + 'integrations';

  public static WIDGET = 'widgets';
  public static SL_WIDGET_ROUTE = '/' + 'widgets';

  public static PAYMENT_ACCOUNT_SETTING = 'paccount';
  public static SL_PAYMENT_ACCOUNT_SETTING = '/' + 'paccount';

  public static MASTER_PLAN = 'masterplan';
  public static SL_MASTER_PLAN_ROUTE = '/' + AppConstants.MASTER_PLAN;

  public static PLAN = 'plan';
  public static SL_PLAN_ROUTE = '/' + AppConstants.PLAN;

  public static TENANT_PLAN = 'tenantplan';
  public static SL_TENANT_PLAN_ROUTE = '/' + 'tenantplan';

  public static TENANT_ROUTE = 'tenant';
  public static SL_TENANT_ROUTE = '/' + 'tenant';

  public static TPLAN_ROUTE = 'tplan';
  public static SL_TPLAN_ROUTE = '/' + AppConstants.TPLAN_ROUTE;

  public static GET_STARTED = 'getstarted';
  public static SL_GET_STARTED = '/' + AppConstants.GET_STARTED;

  public static LOGIN = 'login';
  public static SL_LOGIN = '/' + AppConstants.LOGIN;

  public static InternalPlanNameMonthly = {
    ORGANIZE_PLAN_MONTHLY: 'organize_monthly_plan',
    EXPAND_PLAN_MONTHLY: 'expand_monthly_plan',
    LEAP_PLAN_MONTHLY: 'leap_monthly_plan',
    FOREVER_FREE_PLAN: 'free_plan',
  };
  public static InternalPlanNameYearly = {
    ORGANIZE_PLAN_YEARLY: 'organize_yearly_plan',
    EXPAND_PLAN_YEARLY: 'expand_yearly_plan',
    LEAP_PLAN_YEARLY: 'leap_yearly_plan',
  };

  public static Plans = {
    GOLD_PLAN: 'Gold',
    FREE_PLAN: 'free_plan',
  };
  public static Item = {
    ACTIVE_STATUS: 'active',
    ACCOUNT: 'account',
    PRODUCT: 'product',
    STORE: 'store',

    MEMBERSHIP: 'membership',
    MEMBERSHIP_TYPE_RECURRING: 'recurring',
    MEMBERSHIP_TYPE_CARD: 'card',
    MEMBERSHIP_TYPE_UNLIMITED: 'unlimited',
    PURCHASE_TYPE_CARD: 'creditcard',
    PURCHASE_TYPE_ACCOUNT: 'account',
    PURCHASE_TYPE_CASH: 'cash',
  };

  public static Offer = {
    ACTIVE_STATUS: 'ACTIVE',
    DISABLED_STATUS: 'DISABLED',
    EXPIRED_STATUS: 'EXPIRED',
    ACTIVE_COLOR: 'panel-blue',
    DISABLED_COLOR: 'panel-darken',
    EXPIRED_COLOR: 'panel-redLight',
    ACTIVE_COLOR_CODE: '#346597',
    DISABLED_COLOR_CODE: '#404040',
    EXPIRED_COLOR_CODE: '#a65858',
    OFFERTYPE_FLAT: 'flat',
    OFFERTYPE_BUYFIRSTANDGETOTHER: 'buyFirstAndGetOther',
    ITEMCATETORY_ITEM: 'item',
    ITEMCATETORY_PRODUCT: 'product',
    ITEMCATETORY_COURSE: 'course',
    ITEMCATETORY_ACCOUNT: 'account',
    ITEMCATETORY_MEMBERSHIP: 'membership',
    ITEMCOURSE: 'category',
    FIRST_ITEM_DISCOUNT: 'FIRST_ITEM_DISCOUNT',
    SECOND_ITEM_DISCOUNT: 'SECOND_ITEM_DISCOUNT',
  };
  public static PERCENTAGE = 'percentage';
  public static DOLLARS = 'dollars';

  public static Payment = {
    NONCE_PAYMENT: 'nonce',
    PAYMENT_TYPE_CASH: 'cash',
    PAYMENT_TYPE_ACCOUNT: 'account',
    PSPRING_CARD: 'ps_card',
    PSPRING_ACH: 'ps_ach',
    PSPRING_CLIENT: 'ps_acct',
    NONCE_PAYMENT_ACH: 'ach',
    NONCE_PAYMENT_STR: 'card',
    INSTAMOJO_CARD: 'im_card',
    INSTAMOJO_PAYMENT_TYPE: 'instamojo',
    STRIPE_CARD: 's_card',
    STRIPE_PAYMENT_TYPE: 'stripe',
    STRIPE_CLIENT: 's_acct',
    PAYTM_CARD: 'p_card',
    PAYTM_PAYMENT_TYPE: 'paytm',
    PAYTM_CREDIT_CARD: 'paytm_credit_card',
    PAYTM_DEBIT_CARD: 'paytm_debit_card',
    PAYTM_NET_BANKING: 'paytm_net_banking',
    PAYTM_UPI: 'paytm_upi',
    //paytm_upi
  };
  public static PaytmCartType = {
    PAYTM_CREDIT_CARD: 'paytm_credit_card',
  };

  public static PaymentName = {
    // NONCE_PAYMENT: "nonce",
    PAYMENT_TYPE_CASH: 'Cash',
    PAYMENT_TYPE_ACCOUNT: 'Account',
    PSPRING_CARD: 'Credit Card',
    // PSPRING_ACH: "ps_ach",
    PSPRING_CLIENT: 'ACH Banking',
    // NONCE_PAYMENT_ACH: "ach",
    // NONCE_PAYMENT_STR: "card",
    // INSTAMOJO_CARD: "im_card",
    // INSTAMOJO_PAYMENT_TYPE: "instamojo",
    // STRIPE_CARD: "s_card",
    // STRIPE_PAYMENT_TYPE: "stripe",
    // STRIPE_CLIENT: "s_acct",
    PAYTM_CARD: 'Paytm',
    PAYTM_PAYMENT_TYPE: 'paytm',
    PAYTM_CREDIT_CARD: 'paytm',
    PAYTM_DEBIT_CARD: 'paytm',
    PAYTM_NET_BANKING: 'paytm',
    PAYTM_UPI: 'paytm',
  };

  public static PAYMENT_TYPE = {
    BRAINTREE_PAYMENT: 'BRAINTREE',
    PSPRING_PAYMENT: 'PSPRING',
    INSTAMOJO_PAYMENT: 'INSTAMOJO',
    STRIPE_PAYMENT: 'STRIPE',
    RAZORPAY_PAYMENT: 'RAZORPAY',
    PHONEPE_PAYMENT: 'PHONEPE',
    PAYTM_PAYMENT: 'PAYTM',
    CASH_PAYMENT: 'cash',
    CHEQUE_PAYMENT: 'other',
  };
  public static INSTAMOJO_TYPE = 'instamojo_payment_type';
  public static INSTAMOJO_VALUE = 'instamojo';
  public static ZOOMURL_KEY = 'code';
  public static ZOOMURL_CODE = 'zoom_code';

  public static STRIPE_KEY = 'stripe';
  public static STRIPE_CODE = 'stripe_auth_code';

  public static TIMEFORMAT = {
    AM: 'AM',
    PM: 'PM',
  };

  public static CUSTOM_FORM_EVENT_TYPE = 'EVENT_REGISTRATION';
  public static FULLNAME = 'fullname';
  public static EMAIL = 'email';
  public static PHONE = 'phone';
  public static FIRSTNAME = 'firstname';
  public static LASTNAME = 'lastname';

  public static PlanPeriod = {
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    MONTH: 'Month',
    YEAR: 'Year',
  };
  public static PlanNameDesc = {
    FREE: 'Free',
    ORGANIZE: 'Organize',
    EXPAND: 'Growth',
    LEAP: 'Leap',
  };

  public static PlanMonthPrice = {
    FREE: '0',
    ORGANIZE: '25',
    EXPAND: '65',
    LEAP: '129',
  };
  public static PlanYearPrice = {
    ORGANIZE: '300',
    EXPAND: '660',
    LEAP: '1320',
  };
  public static PlanMonthIndiaPrice = {
    ORGANIZE: '2945',
    EXPAND: '4786',
    LEAP: '9498',
  };
  public static PlanYearIndiaPrice = {
    ORGANIZE: '30941',
    EXPAND: '48620',
    LEAP: '97240',
  };

  public static SCHOOL_ID_LABEL = 'School Id';
  public static ORG_ID_LABEL = 'Org Id';
  public static MINDSYNCKKIDS_ORG_ID = 'mindsynckids';
  public static KOOKABOORA_ORG_ID = 'kookaboora';
  public static DEFAULT_CURRENCY = 'USD';
  public static ACTIVE_CARD_OTHER = 'other';
  public static THIRD_PARTY_OTHER = 'other';
  public static B2C_ORG = 'b2cmindsyncschool';
  public static MINDSYNC_USA_ORG = 'mindsynckidsusa';
  public static TEST_ORG = 'pankajtest5';
  public static M300_ORG = 'm300'
  public static SMDUMMY = "smdummy";

  public static PaymentStatus = {
    PENDING: 'pending',
    PARTIAL: 'partial',
    COMPLETE: 'complete',
    FAILED: 'failed',
    CONFIRM: 'confirm',
    REMINDER: 'reminder',
  };

  public static emailTemplateId = {
    FAILED: 'invoice_failed',
    PENDING: 'invoice_pending',
    COMPLETE: 'invoice_success',
    REMINDER: 'invoice_reminder',
  };
  public static DEMO_COURSE = 'Introductory Session';
  public static defaultInternalWebsitePages = new Map([
    ['home', { name: 'home', title: 'Home', url: 'home', isPublished: false, isStandadPages: false, sequence: 500 }],
    ['about', { name: 'about', title: 'About', url: 'about', isPublished: false, isStandadPages: false, sequence: 9999 }],
    ['contact', { name: 'contact', title: 'Contact', url: 'contact', isPublished: false, isStandadPages: false, sequence: 1000 }],
  ]);

  public static defaultStandardWebsitePages = new Map([
    ['calendar', { name: 'calendar', title: 'Calendar', url: 'calendar', isPublished: false, isStandadPages: true, sequence: 300 }],
    ['courses', { name: 'courses', title: 'Courses', url: 'about', isPublished: false, isStandadPages: true, sequence: 301 }],
    ['donations' , {name:'donations', title:'Donations',url: 'donations', isPublished: false , isStandadPages: true , sequence: 307}],
    ['events', { name: 'events', title: 'Events', url: 'contact', isPublished: false, isStandadPages: true, sequence: 302 }],
    ['merchandise', { name: 'merchandise', title: 'Merchandise', url: 'products', isPublished: false, isStandadPages: true, sequence: 302 }],
    ['forms', { name: 'forms', title: 'Forms', url: 'forms', isPublished: false, isStandadPages: true, sequence: 303 }],
    ['termsofservice', { name: 'termsofservice', title: 'Terms Of Service', url: '', isPublished: false, isStandadPages: true, sequence: 305}],
    ['privacypolicy', { name: 'privacypolicy', title: 'Privacy Policy', url: '', isPublished: false, isStandadPages: true, sequence: 306}],
    ['donations' , {name:'donations', title:'Donations',url: 'pdonations', isPublished: false , isStandadPages: true , sequence: 307}],
    ['mediagallery' , {name:'mediagallery', title:'Media Gallery',url: 'eventgallery', isPublished: false , isStandadPages: true , sequence: 308}]
  ]);

  // placeholders
  public static DEFAULT_PREVIEW_PLACEHOLDER = "https://res.cloudinary.com/wajooba/image/upload/c_thumb,h_320,w_480/v1692705773/master/placeholder.jpg";

  public static CATEGORY_TYPE_BUNDLE = "BUNDLE"
  public static CATEGORY_TYPE_SERVICE = "SERVICE"
  public static CATEGORY_TYPE_PRODUCT = "PRODUCT"
  
  public static PRODUCT_CATEGORY_TYPE_MAP = new Map([
    ['bundle', this.CATEGORY_TYPE_BUNDLE],
    ['course', this.CATEGORY_TYPE_SERVICE],
    ['product', this.CATEGORY_TYPE_PRODUCT]
  ])

  public static CATEGORY_PRODUCT_TYPE_MAP = new Map(
    Array.from(this.PRODUCT_CATEGORY_TYPE_MAP, ([key, value]) => [value, key])
  );

}
