<form [formGroup]="phoneForm">
  <div fxLayout="row" fxLayoutGap="5px">
    <mat-form-field *ngIf="isMultiCountrySupport" appearance="outline" fxFlex="20">
      <input [readonly]="isReadOnly" type="text" placeholder="Pick Country Code" autocomplete="off" class="right-align"
        matInput formControlName="extensionNumber" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option [value]="country.dial_code" *ngFor="let country of countryCodeList">
          <span class="flag-icon flag-icon-{{ country.code | lowercase }} flag-icon-squared"></span>
          {{ country.dial_code }} {{ country.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="80">
      <mat-label *ngIf="heading">Phone Number</mat-label>
      <input [readonly]="isReadOnly" [mask]="mask" matInput formControlName="phoneNumber" class="right-align"
        placeholder="e.g 408-123-4567" required />
      <mat-error style="margin-top: -2px;">Phone Number is <strong>required</strong></mat-error>
    </mat-form-field>
  </div>
</form>