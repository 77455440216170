import { Component, OnDestroy, OnInit, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUtilsService } from 'app/core/services/apputils.service';
import { TimeZone } from 'app/main/shared/enums/time-zone';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { AppSettingsService } from '../../services/app-settings.service';
import { TenantService } from 'app/core/services/tenant.service';
import { Tenant } from 'app/core/models/tenant';

@Component({
  selector: 'app-franchise-add-edit',
  templateUrl: './franchise-add-edit.component.html',
  styleUrls: ['./franchise-add-edit.component.scss']
})
export class FranchiseAddEditComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean = false;
  issubmitting = false;
  franchiseData:any = {};
  phone: string="";
  guId: any;
  timeZoneList: any = [];
  isEditMode: boolean = false;
  tenant: any;
  // Private
  subs = new SubSink();
  public countryCode: string;
  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _chngDetect: ChangeDetectorRef,
    private tenantService: TenantService
  ) {

  }

  ngOnInit() {

    
    this.timeZoneList = TimeZone;
    this.bindFormData(this.franchiseData);

    this.subs.add(
      this._route.params.subscribe((param: any) => {
        this.guId = param["id"];
        if (this.guId) { 
          this.isEditMode = false;
          this._settingsService.getFranchise(this.guId).subscribe(
            (response: any) => {
              this.franchiseData = response;
              this.setPhoneNumber(this.franchiseData.phone);
              this.bindFormData(this.franchiseData);
              this._chngDetect.detectChanges();
              this.isEditMode = true;
            },
            (error) => {
              this.toastr.error(error);
            }
          )
        }else{
          this.getTenantService();
        }
      })
    );
    this.countryCode = this.getCountryCode(this.franchiseData)
  }


  getTenantService() {
    this.subs.add(
      this.tenantService.tenant$.subscribe((tenant: Tenant) => {
        this.tenant = tenant; 
        this.franchiseData.timezone = this.tenant.timezone;
        this.phone = this.getCountryCode(this.tenant);
        this.bindFormData(this.franchiseData);
       })
    );
  } 

  bindFormData(data): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      businessName: [data.name,Validators.required],
      orgId: [data.orgId ,Validators.required],
      phone: [data.phone, Validators.required],
      timezone: [data.timezone],
      user: this._formBuilder.group({
        name: [data.fromEmailName ?? "", Validators.required],
        email: [data.fromEmail ?? "", Validators.required],
        password: [data.user?.password ?? ""],
        // connectionName: [data.user?.connectionName ?? ""],
      }),
      address: this._formBuilder.group({
        line1: [data.address?.line1 ?? ""],
        line2: [data.address?.line2 ?? ""],
        city: [data.address?.city ?? "", Validators.required],
        state: [data.address?.state ?? "", Validators.required],
        zip: [data.address?.zip ?? "", [Validators.required, 
            Validators.minLength(5), 
            Validators.maxLength(10),
            Validators.pattern(/^-?(0|[1-9]\d*)?$/)
          ]
        ],
        //country: [data.address?.country?.name ?? "", Validators.required],
      })
    });
  }

  setPhoneNumber(phone) {
    if(phone && phone.match(/\-/g)){
      this.phone = phone;
    }else{
      this.phone = this.getCountryCode(this.franchiseData.timezone) + phone;
    }
  }

  getPhoneNumber(phoneNumber: string) {
    this.phone = phoneNumber;
    this.form.get("phone").setValue(this.phone);
    if (this.phone === '') {
      this.form.get("phone").setValidators(Validators.required);
    }
  }

  getCountryCode(timezone){
    return AppUtilsService.getCountryDialCode(timezone);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }

  submit() {
    if (this.form.valid) {
      if (!this.guId) {
            this.subs.add(
              this._settingsService.postFranchise(this.form.value).subscribe(
                (next) => {
                  this.toastr.success("Successfully created franchise.");
                  this._router.navigate(['/franchise']);
                },
                (error) => {
                  this.toastr.error(error);
                }
              )
            );
      } else {
        let payload = {
          accountOwner: this.franchiseData.accountOwner,
          accountOwnerName: this.franchiseData.accountOwnerName,
          address: {
              "city": this.getFormControlValue('address.city'),
              "line1": this.getFormControlValue('address.line1'),
              "line2": this.getFormControlValue('address.line2'),
              "state": this.getFormControlValue('address.state'),
              "zip": this.getFormControlValue('address.zip')
          },
          dateCreatedStr: this.franchiseData.dateCreatedStr,
          fromEmail: this.getFormControlValue('user.email'),
          fromEmailName: this.getFormControlValue('user.name'),
          guid: this.franchiseData.guid,
          name: this.getFormControlValue('businessName'),
          orgId: this.getFormControlValue('orgId'),
          phone: this.getFormControlValue('phone')
        };
        this.subs.add(
          this._settingsService.updateFranchise(this.guId, payload).subscribe(
            (next) => {
              this.toastr.success("Successfully updated franchise.");
              this._router.navigate(['/franchise']);
            },
            (error) => {
              this.toastr.error(error);
            }
          )
        );
      }
    } else {
      this.toastr.error("Invalid Form!");
    }
  }

  getFormControlValue(controlName: string) {
    return this.form.get(controlName).value;
  }

  hasError(element: string, error: string) {
    return this.form.get(element).hasError(error) && this.form.get(element).touched;
  }
}
